// export const urlAPI = "http://127.0.0.1:8000/api";
// export const urlAPI = "https://api-tlaa-dot-ttla-dev.as.r.appspot.com/api";
export const urlAPI = "https://api-tlaa-dot-tlaa-prod.as.r.appspot.com/api";

export const headerCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("tlaa_access_token"),
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
