import axios from "axios";
import * as config from "./../config";


export function postLogin(data) {
  return axios
    .post(config.urlAPI + "/login", data)
    .then((res) => {
      console.log(`login ok`, res);
      return res;
    })
    .catch((err) => {
      console.log(`login err`, err);
      return err.response;
    });
}

export function putForgotPassword(data) {
  return axios
    .put(config.urlAPI + "/forgotpw", data)
    .then((res) => {
      console.log(`login ok`, res);
      return res;
    })
    .catch((err) => {
      console.log(`login err`, err);
      return err.response;
    });
}

export function GetUserMe() {
  return axios
    .get(config.urlAPI + "/user/me", config.headerCallAPI)
    .then((res) => {
      // console.log(res);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postLogout() {
  return axios
    .post(config.urlAPI + "/logout")
    .then((res) => {
      console.log(`logout ok`, res);
      return res;
    })
    .catch((err) => {
      console.log(`logout err`, err);
      return err.response;
    });
}
