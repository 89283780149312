import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Overview1 from "../img/overview1.png";
import Overview2 from "../img/overview2.png";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import moment from "moment";
import { getOverview, getOverviewGraph } from "./service";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(
  ArcElement,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

export default function Overview() {
  const valueYear = moment().format("YYYY");
  const valueMonth = moment().format("MM");
  const StringMonth = moment().format("MMMM");

  const [dataOverview, setDataOverview] = useState([]);
  const [dataGraph, setDataGraph] = useState({});

  const dataOrd = [];
  const dataInd = [];
  const dataGro = [];
  const dataPA = [];
  const dataTotal = [];

  const [loading, setLoading] = useState(true);

  dataOrd.push(dataGraph.ord1);
  dataOrd.push(dataGraph.ord2);
  dataOrd.push(dataGraph.ord3);
  dataInd.push(dataGraph.ind1);
  dataInd.push(dataGraph.ind2);
  dataInd.push(dataGraph.ind3);
  dataGro.push(dataGraph.grp1);
  dataGro.push(dataGraph.grp2);
  dataGro.push(dataGraph.grp3);
  dataPA.push(dataGraph.pa1);
  dataPA.push(dataGraph.pa2);
  dataPA.push(dataGraph.pa3);
  dataTotal.push(dataGraph.total_s);
  dataTotal.push(dataGraph.total_f);
  dataTotal.push(dataGraph.total_r);

  React.useEffect(() => {
    DataOverview();
    DataOverviewGraph();
  }, []);

  const data = {
    labels: ["เบี้ยฯ ปีแรก", "เบี้ยฯ ต่ออายุ", "เบี้ยฯ จ่ายครั้งเดียว"],
    datasets: [
      {
        label: "# of Votes",
        data: dataTotal,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const data2 = {
    labels: ["เบี้ยฯ จ่ายครั้งเดียว", "เบี้ยฯ ปีแรก", "เบี้ยฯ ต่ออายุ"],
    datasets: [
      {
        label: "Ordinary",
        data: dataOrd,
        backgroundColor: "#0d47a1",
      },
      {
        label: "Industrial",
        data: dataInd,
        backgroundColor: "#40c4ff",
      },
      {
        label: "Group",
        data: dataGro,
        backgroundColor: "#673ab7",
      },
      {
        label: "PA",
        data: dataPA,
        backgroundColor: "#ff9100",
      },
    ],
  };

  //////////////// Get DataMonthly List ////////////////
  const DataOverview = () => {
    let qString = "?year=" + valueYear + "&month=" + valueMonth;

    getOverview(qString).then((res) => {
      console.log("DataOverview", res.data);
      if (res && res.status === 200) {
        setDataOverview(res.data);
      }
      setLoading(false);
    });
  };

  //////////////// Get DataMonthly Graph ////////////////
  const DataOverviewGraph = () => {
    let qString = "?year=" + valueYear + "&month=" + valueMonth;

    getOverviewGraph(qString).then((res) => {
      console.log("Datagraph", res.data);
      if (res && res.status === 200) {
        setDataGraph(res.data[0]);
      }
      setLoading(false);
    });
  };

  return (
    <Box
      style={{
        margin: "55px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/*  ////////////////////////// Main Topic Pages ////////////////////////// */}

      <Box>
        <Typography
          style={{
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "1.9rem",
          }}
        >
          Overview
        </Typography>

        <Typography
          sx={{
            mt: 3,
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "1.7rem",
          }}
        >
          {`ภาพรวมเบี้ยประกันชีวิต ${StringMonth} ปี  ${valueYear}`}
        </Typography>

        <Box
          sx={{
            mt: 4,
            ml: 8,
            mr: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end", mb: 3 }}>
            <Typography variant="h6">หน่วย : ล้านบาท</Typography>
          </Box>

          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 3 }}>
              {dataOverview.map((data) => (
                <>
                  <Paper
                    elevation={1}
                    sx={{
                      width: "33%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography fontSize={34}>เบี้ยฯ รับรวม</Typography>
                      <Typography fontSize={38}>
                        {new Intl.NumberFormat().format(data.total_premium)}
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {data.percent_premium < 0 ? (
                          <>
                            <Typography fontSize={44} color="#d50000">
                              <ArrowDropDownSharpIcon fontSize="30px" />
                            </Typography>
                            <Typography fontSize={38} color="#d50000">
                              {`  ${new Intl.NumberFormat().format(
                                data.percent_premium
                              )} %`}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography fontSize={44} color="#4caf50">
                              <ArrowDropUpIcon fontSize="30px" />
                            </Typography>
                            <Typography fontSize={38} color="#4caf50">
                              {`  ${new Intl.NumberFormat().format(
                                data.percent_premium
                              )} %`}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Paper>
                  <Paper elevation={0} sx={{ width: "33%" }}>
                    <Paper
                      elevation={0}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Logo1"
                            src={Overview1}
                            style={{ width: "10rem", height: "7.5rem" }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3px",
                          }}
                        >
                          <Typography fontSize={26}>เบี้ยฯ รายใหม่</Typography>
                          <Typography fontSize={24} color="#1976d2">
                            {new Intl.NumberFormat().format(
                              data.total_firstsingle
                            )}
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            {data.percent_firstsingle < 0 ? (
                              <>
                                <Typography fontSize={32} color="#d50000">
                                  <ArrowDropDownSharpIcon fontSize="30px" />
                                </Typography>
                                <Typography fontSize={21} color="#d50000">
                                  {`  ${new Intl.NumberFormat().format(
                                    data.percent_firstsingle
                                  )} %`}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography fontSize={32} color="#4caf50">
                                  <ArrowDropUpIcon fontSize="30px" />
                                </Typography>
                                <Typography fontSize={21} color="#4caf50">
                                  {`  ${new Intl.NumberFormat().format(
                                    data.percent_firstsingle
                                  )} %`}
                                </Typography>
                              </>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography fontSize={21}>
                              {` Share ${new Intl.NumberFormat().format(
                                data.share_firstsingle
                              )} %`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>

                    <Box sx={{ display: "flex", mt: 1, width: "100%", gap: 2 }}>
                      <Paper variant="outlined" sx={{ p: 2, width: "50%" }}>
                        <Typography fontSize={20}>เบี้ยฯ ปีแรก</Typography>
                        <Typography fontSize={20} color="#1976d2">
                          {new Intl.NumberFormat().format(data.total_first)}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          {data.percent_first < 0 ? (
                            <>
                              <Typography fontSize={24} color="#d50000">
                                <ArrowDropDownSharpIcon fontSize="30px" />
                              </Typography>
                              <Typography fontSize={18} color="#d50000">
                                {`  ${new Intl.NumberFormat().format(
                                  data.percent_first
                                )} %`}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography fontSize={24} color="#4caf50">
                                <ArrowDropUpIcon fontSize="30px" />
                              </Typography>
                              <Typography fontSize={18} color="#4caf50">
                                {`  ${new Intl.NumberFormat().format(
                                  data.percent_first
                                )} %`}
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Typography
                          fontSize={18}
                        >{` Share ${new Intl.NumberFormat().format(
                          data.share_first
                        )} %`}</Typography>
                      </Paper>
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography fontSize={20}>
                          เบี้ยฯ จ่ายครั้งเดียว
                        </Typography>
                        <Typography fontSize={20} color="#1976d2">
                          {new Intl.NumberFormat().format(data.total_single)}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          {data.percent_single < 0 ? (
                            <>
                              <Typography fontSize={24} color="#d50000">
                                <ArrowDropDownSharpIcon fontSize="30px" />
                              </Typography>
                              <Typography fontSize={18} color="#d50000">
                                {`  ${new Intl.NumberFormat().format(
                                  data.percent_single
                                )} %`}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography fontSize={24} color="#4caf50">
                                <ArrowDropUpIcon fontSize="30px" />
                              </Typography>
                              <Typography fontSize={18} color="#4caf50">
                                {`  ${new Intl.NumberFormat().format(
                                  data.percent_single
                                )} %`}
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Typography
                          fontSize={18}
                        >{` Share ${new Intl.NumberFormat().format(
                          data.share_single
                        )} %`}</Typography>
                      </Paper>
                    </Box>
                  </Paper>
                  <Paper elevation={0} sx={{ width: "33%" }}>
                    <Paper
                      elevation={0}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Logo1"
                            src={Overview2}
                            style={{ width: "6rem", height: "7.5rem" }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3px",
                          }}
                        >
                          <Typography fontSize={26}>เบี้ยฯ ต่ออายุ</Typography>
                          <Typography fontSize={24} color="#1976d2">
                            {new Intl.NumberFormat().format(data.total_renew)}
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            {data.percent_renew < 0 ? (
                              <>
                                <Typography fontSize={32} color="#d50000">
                                  <ArrowDropDownSharpIcon fontSize="30px" />
                                </Typography>
                                <Typography fontSize={21} color="#d50000">
                                  {`  ${new Intl.NumberFormat().format(
                                    data.percent_renew
                                  )} %`}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography fontSize={32} color="#4caf50">
                                  <ArrowDropUpIcon fontSize="30px" />
                                </Typography>
                                <Typography fontSize={21} color="#4caf50">
                                  {`  ${new Intl.NumberFormat().format(
                                    data.percent_renew
                                  )} %`}
                                </Typography>
                              </>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography
                              fontSize={21}
                            >{` Share ${new Intl.NumberFormat().format(
                              data.share_renew
                            )} %`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>

                    <Paper
                      variant="outlined"
                      sx={{
                        p: 5.5,
                        mt: 1,
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <Typography fontSize={22}>
                        อัตตราความคงอยู่ของกรมธรรม์
                      </Typography>
                      <Typography fontSize={22} color="#1976d2">
                        {` ${new Intl.NumberFormat().format(
                          data.rate_renew
                        )} %`}
                      </Typography>
                    </Paper>
                  </Paper>
                </>
              ))}
            </Box>
          )}

          <Box
            style={{
              display: "flex",
              padding: "20px",
              margin: "50px",
              marginTop: "100px",
              justifyContent: "center",
            }}
          >
            <Box>
              <Pie
                data={data}
                style={{
                  width: "350px",
                  height: "350px",
                  marginRight: "200px",
                }}
              />
            </Box>
            <Box>
              <Bar
                options={options}
                data={data2}
                style={{ width: "700px", height: "400px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
