import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Typography, TextField, Button, MenuItem, Modal } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { FiEdit } from "react-icons/fi";
import { BsFillXCircleFill } from "react-icons/bs";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getCompanyAll,
  getMonthly,
  // postAddformA,
  // postAddformB,
  deleteMonthly,
  channelImportList,
  postChannelData,
  checkRegisCompany,
  deleteChannelData,
} from "../service";
import LinearProgress from "@mui/material/LinearProgress";
import { Empty } from "antd";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-month-picker/css/month-picker.css";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import { resolveStyleConfig } from "@chakra-ui/react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

const styleModaldelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  {
    id: "Insurance",
    label: "Insurance Company",
    align: "left",
  },
  {
    id: "Month",
    label: "Month",
    align: "center",
  },
  {
    id: "Year",
    label: "Year",
    align: "center",
  },
  //   {
  //     id: "Form",
  //     label: "Form",
  //     align: "center",
  //   },
  //   {
  //     id: "Status",
  //     label: "Status",
  //     align: "center",
  //   },
  {
    id: "Action",
    label: "Action",
    align: "center",
  },
];

const forms = [
  {
    value: "All",
    label: "-- All Form --",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
];

const statuses = [
  {
    value: "All",
    label: "-- All Status --",
  },
  {
    value: "Confirm",
    label: "Confirm",
  },
  {
    value: "Wait to confirm",
    label: "Wait to confirm",
  },
];

const monthTH = [
  { short: "ม.ค.", full: "มกราคม", num: "01" },
  { short: "ก.พ.", full: "กุมภาพันธ์", num: "02" },
  { short: "มี.ค.", full: "มีนาคม", num: "03" },
  { short: "เม.ย.", full: "เมษายน", num: "04" },
  { short: "พ.ค.", full: "พฤษภาคม", num: "05" },
  { short: "มิ.ย.", full: "มิถุนายน", num: "06" },
  { short: "ก.ค.", full: "กรกฎาคม", num: "07" },
  { short: "ส.ค.", full: "สิงหาคม", num: "08" },
  { short: "ก.ย.", full: "กันยายน", num: "09" },
  { short: "ต.ค.", full: "ตุลาคม", num: "10" },
  { short: "พ.ย.", full: "พฤศจิกายน", num: "11" },
  { short: "ธ.ค.", full: "ธันวาคม", num: "12" },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Variable for import data from excel ----------
var type = "";
var sub_type = "";
var List = "";
var Agent_Policies = "";
var Agent_Sum_Insured = "";
var Agent_Direct_Premiums = "";
var Broker_Policies = "";
var Broker_Sum_Insured = "";
var Broker_Direct_Premiums = "";
var Bancassurance_Policies = "";
var Bancassurance_Sum_Insured = "";
var Bancassurance_Direct_Premiums = "";
var Direct_Mail_Policies = "";
var Direct_Mail_Sum_Insured = "";
var Direct_Mail_Direct_Premiums = "";
var Tele_Marketing_Policies = "";
var Tele_Marketing_Sum_Insured = "";
var Tele_Marketing_Direct_Premiums = "";
var Digital_Policies = "";
var Digital_Sum_Insured = "";
var Digital_Direct_Premiums = "";
var Others_Policies = "";
var Others_Sum_Insured = "";
var Others_Direct_Premiums = "";
var Grand_Total_Policies = "";
var Grand_Total_Sum_Insured = "";
var Grand_Total_Direct_Premiums = "";
//---------------------------------------------------

export default function ImportByChannel() {
  const fileRef = useRef();
  const location = useLocation();
  const [value, setValue] = useState(
    location.state?.dataMonth
      ? `${location.state?.dataYear}-${location.state?.dataMonth}`
      : moment().format("YYYY-MM")
  );
  const [valueYear, setValueYear] = useState(
    location.state?.dataYear
      ? location.state?.dataYear
      : moment().format("YYYY")
  );
  const [valueMonth, setValueMonth] = useState(
    location.state?.dataMonth
      ? location.state?.dataMonth
      : moment().format("MM")
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyname, setCompanyname] = useState("");
  const [form, setForm] = useState("");
  const [status, setStatus] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [sumErrorMsg, setSumErrorMsg] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSh, setLoadingSh] = useState(false);

  const [insurance, setInsurance] = useState([]);
  const [dataMonthly, setDataMonthly] = useState([]);
  const [values, setValues] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingbutton, setloadingbutton] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    handleRemove();
    handleClearData();
    setSumErrorMsg("");
  };

  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [loadingbuttondelete, setloadingbuttondelete] = useState(false);
  const handleOpenModaldelete = () => {
    setOpenModaldelete(true);
  };
  const handleCloseModaldelete = () => setOpenModaldelete(false);

  const handleClickModal = () => {
    setloadingbutton(true);
    addDataChannel();
  };

  const handleClickModaldelete = () => {
    setloadingbuttondelete(true);
    deleteChannel();
  };

  // Snack Bar
  const vertical = "top";
  const horizontal = "right";
  const [openSnack, setOpenSnack] = useState(false);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [dataChannel, setDataChannel] = useState([]);
  const [valuesForm, setValuesForm] = useState({});

  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState({});

  const getDeleteRow = (company_id, year, month) => {
    var deleteForm = {
      month: month,
      year: year,
      company_id: company_id,
    };
    setDeleteId(deleteForm);
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getData = () => {
    let qString = "?";
    if (valueYear) qString = qString + "&year=" + valueYear;
    if (valueMonth) qString = qString + "&month=" + valueMonth;
    if (companyname) qString = qString + "&company=" + companyname;

    channelImportList(qString).then((res) => {
      if (res && res.status === 200) {
        setDataChannel(res.data);
      }
      setLoading(false);
      setLoadingSh(false);
    });
  };

  const [reportMonth, setReportMonth] = useState("");
  const [reportYear, setReportYear] = useState("");
  const [reportMonthNum, setReportMonthNum] = useState("");
  const [reportYearEN, setReportYearEN] = useState("");

  const [company, setCompany] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [template, setTemplate] = useState("");
  const [memberNo, setMemberNo] = useState("");
  const [yearly, setYearly] = useState("");
  const [monthy, setMonthy] = useState("");
  const [dataType, setDataType] = useState("");
  const [order, setOrder] = useState("");

  const acceptableFileName = ["xlsx", "xls"];

  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  //////////////// setting RowsPerPage ////////////////
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataChannel.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showAlertWrongWord = (cell, word) => {
    Swal.fire(
      "Invalid form?",
      `Form is not match ,Please check word at ${cell} cell must be '${word}'`,
      "question"
    );
    handleRemove();
  };

  const handleClearData = () => {
    setCompany("");
    setCompanyID("");
    setReportMonth("");
    setReportMonthNum("");
    setReportYear("");
    setReportYearEN("");
    setValuesForm({});
  };

  function yearEN(yTH) {
    return yTH - 543;
  }

  function clearRow() {
    // type = "";
    // sub_type = "";
    // List = "";
    Agent_Policies = "";
    Agent_Sum_Insured = "";
    Agent_Direct_Premiums = "";
    Broker_Policies = "";
    Broker_Sum_Insured = "";
    Broker_Direct_Premiums = "";
    Bancassurance_Policies = "";
    Bancassurance_Sum_Insured = "";
    Bancassurance_Direct_Premiums = "";
    Direct_Mail_Policies = "";
    Direct_Mail_Sum_Insured = "";
    Direct_Mail_Direct_Premiums = "";
    Tele_Marketing_Policies = "";
    Tele_Marketing_Sum_Insured = "";
    Tele_Marketing_Direct_Premiums = "";
    Digital_Policies = "";
    Digital_Sum_Insured = "";
    Digital_Direct_Premiums = "";
    Others_Policies = "";
    Others_Sum_Insured = "";
    Others_Direct_Premiums = "";
    Grand_Total_Policies = "";
    Grand_Total_Sum_Insured = "";
    Grand_Total_Direct_Premiums = "";
  }

  //////////////// Import file xlsx ////////////////

  const handleFile = async (e) => {
    const myFile = e.target.files[0];

    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      Swal.fire({
        icon: "warning",
        text: "Invalid File Type!",
      });
      return;
    }

    //Read The XLSX MetaData
    const data = await myFile.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // table 1-2
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]]; // table 3-4
    const jsonData2 = XLSX.utils.sheet_to_json(worksheet2);

    // console.log("datawb", jsonData);

    setFile(myFile);
    setFileName(myFile.name);
    setErrorMsg("");
    setSumErrorMsg("");

    const formTitle = jsonData[0].IMPORT_FORM;
    const formCompany = jsonData[1].IMPORT_FORM;

    // Verify form title "รายงานการรับประกันชีวิตจำแนกตามช่องทางการขาย"
    if (!formTitle) {
      Swal.fire(
        "Invalid form?",
        "Form is not match ,Please check 'IMPORT_FORM' word at A1 cell !!",
        "question"
      );
      handleRemove();
      return;
    }
    if (!formTitle.startsWith("รายงานการรับประกันชีวิตจำแนกตามช่องทางการขาย")) {
      Swal.fire(
        "Invalid form?",
        "Form is not match ,Please check !!",
        "question"
      );
      handleRemove();
      return;
    }

    // Verify company name
    // var company_id = "";
    var companyName = "";

    if (formCompany) {
      let qString = "?company_name=" + formCompany.replace("บริษัท", "").trim();
      checkRegisCompany(qString).then((res) => {
        if (res && res.status === 200 && res.data.success === true) {
          // company_id = res.data.company_id;
          setCompany(formCompany);
          setCompanyID(res.data.company_id);
        } else {
          Swal.fire(
            "Invalid company name?",
            "Company name has not been registered, Please check !!",
            "question"
          );
          handleRemove();
          return;
        }
      });
    }
    companyName = formCompany.replace("บริษัท", "").trim();

    // Get month & year
    var monthNum = "";
    var yearEn = "";
    const formMonth = formTitle
      .substring(
        formTitle.indexOf("เดือน") + "เดือน".length,
        formTitle.indexOf("พ.ศ.")
      )
      .trim();
    const formYear = formTitle
      .substring(formTitle.indexOf("พ.ศ.") + "พ.ศ.".length)
      .trim();
    setReportYear(formYear);
    setReportYearEN(yearEN(formYear));
    yearEn = yearEN(formYear);

    for (let i of monthTH) {
      //Find month number
      if (formMonth.includes(i.full)) {
        setReportMonth(i.full);
        setReportMonthNum(i.num);
        monthNum = i.num;
        break;
      }
    }

    // Get data refer column
    var dataList = [];

    var rowId = "";
    var rowTxt = "";
    var addRow = 0;

    // Table 1-2
    for (let row of jsonData) {
      rowId = row.__rowNum__;
      row.IMPORT_FORM ? (rowTxt = row.IMPORT_FORM.trim()) : (rowTxt = "");

      // Table 1
      if (rowId >= 7 && rowId <= 34) {
        if (rowId == 7) {
          // console.log(`Test row ${rowId}`, rowTxt);
          // Verify wording type ---------------------------------------------------------------------------
          if (rowTxt !== "1. เบี้ยประกันภัยปีแรก First Year Premium") {
            showAlertWrongWord(
              "A8",
              "1. เบี้ยประกันภัยปีแรก First Year Premium"
            );
            break;
          }
          //-------------------------------------------------------------------------------------------
          type = rowTxt;
        } else if (
          rowId == 8 ||
          rowId == 13 ||
          rowId == 16 ||
          rowId == 26 ||
          rowId == 31
        ) {
          // console.log(`Test row ${rowId}`, rowTxt);
          // Verify wording sub_type ---------------------------------------------------------------------------
          if (rowId == 8 && rowTxt !== "1.1 สามัญ") {
            showAlertWrongWord("A9", "1.1 สามัญ");
            break;
          } else if (rowId == 13 && rowTxt !== "1.2 อุตสาหกรรม Industrial") {
            showAlertWrongWord("A14", "1.2 อุตสาหกรรม Industrial");
            break;
          } else if (rowId == 16 && rowTxt !== "1.3 กลุ่ม Group") {
            showAlertWrongWord("A17", "1.3 กลุ่ม Group");
            break;
          } else if (rowId == 26 && rowTxt !== "1.9 สัญญาเพิ่มเติมรายบุคคล") {
            showAlertWrongWord("A27", "1.9 สัญญาเพิ่มเติมรายบุคคล");
            break;
          } else if (
            rowId == 31 &&
            rowTxt !== "1.10 สัญญาเพิ่มเติมประกันกลุ่ม"
          ) {
            showAlertWrongWord("A32", "1.10 สัญญาเพิ่มเติมประกันกลุ่ม");
            break;
          }
          //-------------------------------------------------------------------------------------------
          sub_type = rowTxt;
        } else {
          if (rowId >= 21 && rowId <= 25) {
            // Verify wording sub_type ---------------------------------------------------------------------------
            if (rowId == 21 && rowTxt !== "1.4 บำนาญ Pension") {
              showAlertWrongWord("A22", "1.4 บำนาญ Pension");
              break;
            } else if (rowId == 22 && rowTxt !== "1.5 Universal Life") {
              showAlertWrongWord("A23", "1.5 Universal Life");
              break;
            } else if (rowId == 23 && rowTxt !== "1.6 Unit-Link") {
              showAlertWrongWord("A24", "1.6 Unit-Link");
              break;
            } else if (rowId == 24 && rowTxt !== "1.7 ตากะฟุล") {
              showAlertWrongWord("A25", "1.7 ตากะฟุล");
              break;
            } else if (rowId == 25 && rowTxt !== "1.8 อุบัติเหตุส่วนบุคคล") {
              showAlertWrongWord("A26", "1.8 อุบัติเหตุส่วนบุคคล");
              break;
            }
            //-------------------------------------------------------------------------------------------
            sub_type = rowTxt;
          }
          // Verify wording List ---------------------------------------------------------------------------
          if (rowId == 9 && rowTxt !== "- ตลอดชีพ") {
            showAlertWrongWord("A10", "- ตลอดชีพ");
            break;
          } else if (rowId == 10 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A11", "- สะสมทรัพย์");
            break;
          } else if (rowId == 11 && rowTxt !== "- เฉพาะกาล") {
            showAlertWrongWord("A12", "- เฉพาะกาล");
            break;
          } else if (rowId == 12 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A13", "- อื่นๆ");
            break;
          } else if (rowId == 14 && rowTxt !== "- ทั่วไป") {
            showAlertWrongWord("A15", "- ทั่วไป");
            break;
          } else if (rowId == 15 && rowTxt !== "- ไมโครอินชัวรันส์") {
            showAlertWrongWord("A16", "- ไมโครอินชัวรันส์");
            break;
          } else if (rowId == 17 && rowTxt !== "- ชั่วระยะเวลา") {
            showAlertWrongWord("A18", "- ชั่วระยะเวลา");
            break;
          } else if (rowId == 18 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A19", "- สะสมทรัพย์");
            break;
          } else if (rowId == 19 && rowTxt !== "- คุ้มครองสินเชื่อ") {
            showAlertWrongWord("A20", "- คุ้มครองสินเชื่อ");
            break;
          } else if (
            rowId == 20 &&
            rowTxt !== "- อุบัติเหตุ,นักเรียน และอื่นๆ"
          ) {
            showAlertWrongWord("A21", "- อุบัติเหตุ,นักเรียน และอื่นๆ");
            break;
          } else if (rowId == 27 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A28", "- อุบัติเหตุ");
            break;
          } else if (rowId == 28 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A29", "- สุขภาพ");
            break;
          } else if (rowId == 29 && rowTxt !== "- โรคร้ายแรง") {
            showAlertWrongWord("A30", "- โรคร้ายแรง");
            break;
          } else if (rowId == 30 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A31", "- อื่นๆ");
            break;
          } else if (rowId == 32 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A33", "- อุบัติเหตุ");
            break;
          } else if (rowId == 33 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A34", "- สุขภาพ");
            break;
          } else if (rowId == 34 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A35", "- อื่นๆ");
            break;
          }
          // } else if (rowId == 20 && rowTxt !== "") {
          //   showAlertWrongWord("A21", "");
          //   break;
          // }
          //-------------------------------------------------------------------------------------------

          addRow = 1;
          List = rowTxt;
          row.__EMPTY ? (Agent_Policies = row.__EMPTY) : (Agent_Policies = 0);
          row.__EMPTY_1
            ? (Agent_Sum_Insured = row.__EMPTY_1)
            : (Agent_Sum_Insured = 0);
          row.__EMPTY_2
            ? (Agent_Direct_Premiums = row.__EMPTY_2)
            : (Agent_Direct_Premiums = 0);
          row.__EMPTY_3
            ? (Broker_Policies = row.__EMPTY_3)
            : (Broker_Policies = 0);
          row.__EMPTY_4
            ? (Broker_Sum_Insured = row.__EMPTY_4)
            : (Broker_Sum_Insured = 0);
          row.__EMPTY_5
            ? (Broker_Direct_Premiums = row.__EMPTY_5)
            : (Broker_Direct_Premiums = 0);
          row.__EMPTY_6
            ? (Bancassurance_Policies = row.__EMPTY_6)
            : (Bancassurance_Policies = 0);
          row.__EMPTY_7
            ? (Bancassurance_Sum_Insured = row.__EMPTY_7)
            : (Bancassurance_Sum_Insured = 0);
          row.__EMPTY_8
            ? (Bancassurance_Direct_Premiums = row.__EMPTY_8)
            : (Bancassurance_Direct_Premiums = 0);
          row.__EMPTY_9
            ? (Direct_Mail_Policies = row.__EMPTY_9)
            : (Direct_Mail_Policies = 0);
          row.__EMPTY_10
            ? (Direct_Mail_Sum_Insured = row.__EMPTY_10)
            : (Direct_Mail_Sum_Insured = 0);
          row.__EMPTY_11
            ? (Direct_Mail_Direct_Premiums = row.__EMPTY_11)
            : (Direct_Mail_Direct_Premiums = 0);
          row.__EMPTY_12
            ? (Tele_Marketing_Policies = row.__EMPTY_12)
            : (Tele_Marketing_Policies = 0);
          row.__EMPTY_13
            ? (Tele_Marketing_Sum_Insured = row.__EMPTY_13)
            : (Tele_Marketing_Sum_Insured = 0);
          row.__EMPTY_14
            ? (Tele_Marketing_Direct_Premiums = row.__EMPTY_14)
            : (Tele_Marketing_Direct_Premiums = 0);
          row.__EMPTY_15
            ? (Digital_Policies = row.__EMPTY_15)
            : (Digital_Policies = 0);
          row.__EMPTY_16
            ? (Digital_Sum_Insured = row.__EMPTY_16)
            : (Digital_Sum_Insured = 0);
          row.__EMPTY_17
            ? (Digital_Direct_Premiums = row.__EMPTY_17)
            : (Digital_Direct_Premiums = 0);
          row.__EMPTY_18
            ? (Others_Policies = row.__EMPTY_18)
            : (Others_Policies = 0);
          row.__EMPTY_19
            ? (Others_Sum_Insured = row.__EMPTY_19)
            : (Others_Sum_Insured = 0);
          row.__EMPTY_20
            ? (Others_Direct_Premiums = row.__EMPTY_20)
            : (Others_Direct_Premiums = 0);
          row.__EMPTY_21
            ? (Grand_Total_Policies = row.__EMPTY_21)
            : (Grand_Total_Policies = 0);
          row.__EMPTY_22
            ? (Grand_Total_Sum_Insured = row.__EMPTY_22)
            : (Grand_Total_Sum_Insured = 0);
          row.__EMPTY_23
            ? (Grand_Total_Direct_Premiums = row.__EMPTY_23)
            : (Grand_Total_Direct_Premiums = 0);
        }
      }
      // Table 2
      else if (rowId >= 40 && rowId <= 66) {
        if (rowId == 40) {
          // Verify wording type ---------------------------------------------------------------------------
          if (rowTxt !== "2. เบี้ยประกันภัยปีต่อไป Renewal Premium") {
            showAlertWrongWord(
              "A41",
              "2. เบี้ยประกันภัยปีต่อไป Renewal Premium"
            );
            break;
          }
          //-------------------------------------------------------------------------------------------
          type = rowTxt;
        } else if (
          rowId == 41 ||
          rowId == 46 ||
          rowId == 49 ||
          rowId == 58 ||
          rowId == 63
        ) {
          // Verify wording sub_type ---------------------------------------------------------------------------
          if (rowId == 41 && rowTxt !== "2.1 สามัญ Ordinary") {
            showAlertWrongWord("A42", "2.1 สามัญ Ordinary");
            break;
          } else if (rowId == 46 && rowTxt !== "2.2 อุตสาหกรรม Industrial") {
            showAlertWrongWord("A47", "2.2 อุตสาหกรรม Industrial");
            break;
          } else if (rowId == 49 && rowTxt !== "2.3 กลุ่ม Group") {
            showAlertWrongWord("A50", "2.3 กลุ่ม Group");
            break;
          } else if (rowId == 58 && rowTxt !== "2.8 สัญญาเพิ่มเติมรายบุคคล") {
            showAlertWrongWord("A59", "2.8 สัญญาเพิ่มเติมรายบุคคล");
            break;
          } else if (
            rowId == 63 &&
            rowTxt !== "2.9 สัญญาเพิ่มเติมประกันกลุ่ม"
          ) {
            showAlertWrongWord("A64", "2.9 สัญญาเพิ่มเติมประกันกลุ่ม");
            break;
          }
          //-------------------------------------------------------------------------------------------
          sub_type = rowTxt;
        } else {
          if (rowId >= 54 && rowId <= 57) {
            // Verify wording sub_type ---------------------------------------------------------------------------
            if (rowId == 54 && rowTxt !== "2.4 บำนาญ Pension") {
              showAlertWrongWord("A55", "2.4 บำนาญ Pension");
              break;
            } else if (rowId == 55 && rowTxt !== "2.5 Universal Life") {
              showAlertWrongWord("A56", "2.5 Universal Life");
              break;
            } else if (rowId == 56 && rowTxt !== "2.6 Unit-Link") {
              showAlertWrongWord("A57", "2.6 Unit-Link");
              break;
            } else if (rowId == 57 && rowTxt !== "2.7 ตากะฟุล") {
              showAlertWrongWord("A58", "2.7 ตากะฟุล");
              break;
            }
            //-------------------------------------------------------------------------------------------
            sub_type = rowTxt;
          }
          // Verify wording List ---------------------------------------------------------------------------
          if (rowId == 42 && rowTxt !== "- ตลอดชีพ") {
            showAlertWrongWord("A43", "- ตลอดชีพ");
            break;
          } else if (rowId == 43 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A44", "- สะสมทรัพย์");
            break;
          } else if (rowId == 44 && rowTxt !== "- เฉพาะกาล") {
            showAlertWrongWord("A45", "- เฉพาะกาล");
            break;
          } else if (rowId == 45 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A46", "- อื่นๆ");
            break;
          } else if (rowId == 47 && rowTxt !== "- ทั่วไป") {
            showAlertWrongWord("A48", "- ทั่วไป");
            break;
          } else if (rowId == 48 && rowTxt !== "- ไมโครอินชัวรันส์") {
            showAlertWrongWord("A49", "- ไมโครอินชัวรันส์");
            break;
          } else if (rowId == 50 && rowTxt !== "- ชั่วระยะเวลา") {
            showAlertWrongWord("A51", "- ชั่วระยะเวลา");
            break;
          } else if (rowId == 51 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A52", "- สะสมทรัพย์");
            break;
          } else if (rowId == 52 && rowTxt !== "- คุ้มครองสินเชื่อ") {
            showAlertWrongWord("A53", "- คุ้มครองสินเชื่อ");
            break;
          } else if (
            rowId == 53 &&
            rowTxt !== "- อุบัติเหตุ,นักเรียน และอื่นๆ"
          ) {
            showAlertWrongWord("A54", "- อุบัติเหตุ,นักเรียน และอื่นๆ");
            break;
          } else if (rowId == 59 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A60", "- อุบัติเหตุ");
            break;
          } else if (rowId == 60 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A61", "- สุขภาพ");
            break;
          } else if (rowId == 61 && rowTxt !== "- โรคร้ายแรง") {
            showAlertWrongWord("A62", "- โรคร้ายแรง");
            break;
          } else if (rowId == 62 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A63", "- อื่นๆ");
            break;
          } else if (rowId == 64 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A65", "- อุบัติเหตุ");
            break;
          } else if (rowId == 65 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A66", "- สุขภาพ");
            break;
          } else if (rowId == 66 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A67", "- อื่นๆ");
            break;
          }
          //-------------------------------------------------------------------------------------------

          addRow = 1;
          List = rowTxt;
          row.__EMPTY ? (Agent_Policies = row.__EMPTY) : (Agent_Policies = 0);
          row.__EMPTY_1
            ? (Agent_Sum_Insured = row.__EMPTY_1)
            : (Agent_Sum_Insured = 0);
          row.__EMPTY_2
            ? (Agent_Direct_Premiums = row.__EMPTY_2)
            : (Agent_Direct_Premiums = 0);
          row.__EMPTY_3
            ? (Broker_Policies = row.__EMPTY_3)
            : (Broker_Policies = 0);
          row.__EMPTY_4
            ? (Broker_Sum_Insured = row.__EMPTY_4)
            : (Broker_Sum_Insured = 0);
          row.__EMPTY_5
            ? (Broker_Direct_Premiums = row.__EMPTY_5)
            : (Broker_Direct_Premiums = 0);
          row.__EMPTY_6
            ? (Bancassurance_Policies = row.__EMPTY_6)
            : (Bancassurance_Policies = 0);
          row.__EMPTY_7
            ? (Bancassurance_Sum_Insured = row.__EMPTY_7)
            : (Bancassurance_Sum_Insured = 0);
          row.__EMPTY_8
            ? (Bancassurance_Direct_Premiums = row.__EMPTY_8)
            : (Bancassurance_Direct_Premiums = 0);
          row.__EMPTY_9
            ? (Direct_Mail_Policies = row.__EMPTY_9)
            : (Direct_Mail_Policies = 0);
          row.__EMPTY_10
            ? (Direct_Mail_Sum_Insured = row.__EMPTY_10)
            : (Direct_Mail_Sum_Insured = 0);
          row.__EMPTY_11
            ? (Direct_Mail_Direct_Premiums = row.__EMPTY_11)
            : (Direct_Mail_Direct_Premiums = 0);
          row.__EMPTY_12
            ? (Tele_Marketing_Policies = row.__EMPTY_12)
            : (Tele_Marketing_Policies = 0);
          row.__EMPTY_13
            ? (Tele_Marketing_Sum_Insured = row.__EMPTY_13)
            : (Tele_Marketing_Sum_Insured = 0);
          row.__EMPTY_14
            ? (Tele_Marketing_Direct_Premiums = row.__EMPTY_14)
            : (Tele_Marketing_Direct_Premiums = 0);
          row.__EMPTY_15
            ? (Digital_Policies = row.__EMPTY_15)
            : (Digital_Policies = 0);
          row.__EMPTY_16
            ? (Digital_Sum_Insured = row.__EMPTY_16)
            : (Digital_Sum_Insured = 0);
          row.__EMPTY_17
            ? (Digital_Direct_Premiums = row.__EMPTY_17)
            : (Digital_Direct_Premiums = 0);
          row.__EMPTY_18
            ? (Others_Policies = row.__EMPTY_18)
            : (Others_Policies = 0);
          row.__EMPTY_19
            ? (Others_Sum_Insured = row.__EMPTY_19)
            : (Others_Sum_Insured = 0);
          row.__EMPTY_20
            ? (Others_Direct_Premiums = row.__EMPTY_20)
            : (Others_Direct_Premiums = 0);
          row.__EMPTY_21
            ? (Grand_Total_Policies = row.__EMPTY_21)
            : (Grand_Total_Policies = 0);
          row.__EMPTY_22
            ? (Grand_Total_Sum_Insured = row.__EMPTY_22)
            : (Grand_Total_Sum_Insured = 0);
          row.__EMPTY_23
            ? (Grand_Total_Direct_Premiums = row.__EMPTY_23)
            : (Grand_Total_Direct_Premiums = 0);
        }
      }

      if (addRow == 1) {
        // set data row
        dataList.push({
          type: type,
          sub_type: sub_type,
          List: List,
          Agent_Policies: Agent_Policies,
          Agent_Sum_Insured: Agent_Sum_Insured,
          Agent_Direct_Premiums: Agent_Direct_Premiums,
          Broker_Policies: Broker_Policies,
          Broker_Sum_Insured: Broker_Sum_Insured,
          Broker_Direct_Premiums: Broker_Direct_Premiums,
          Bancassurance_Policies: Bancassurance_Policies,
          Bancassurance_Sum_Insured: Bancassurance_Sum_Insured,
          Bancassurance_Direct_Premiums: Bancassurance_Direct_Premiums,
          Direct_Mail_Policies: Direct_Mail_Policies,
          Direct_Mail_Sum_Insured: Direct_Mail_Sum_Insured,
          Direct_Mail_Direct_Premiums: Direct_Mail_Direct_Premiums,
          Tele_Marketing_Policies: Tele_Marketing_Policies,
          Tele_Marketing_Sum_Insured: Tele_Marketing_Sum_Insured,
          Tele_Marketing_Direct_Premiums: Tele_Marketing_Direct_Premiums,
          Digital_Policies: Digital_Policies,
          Digital_Sum_Insured: Digital_Sum_Insured,
          Digital_Direct_Premiums: Digital_Direct_Premiums,
          Others_Policies: Others_Policies,
          Others_Sum_Insured: Others_Sum_Insured,
          Others_Direct_Premiums: Others_Direct_Premiums,
          Grand_Total_Policies: Grand_Total_Policies,
          Grand_Total_Sum_Insured: Grand_Total_Sum_Insured,
          Grand_Total_Direct_Premiums: Grand_Total_Direct_Premiums,
        });
        clearRow();
        addRow = 0;
      }
    }

    // Table 3-4
    for (let row of jsonData2) {
      rowId = row.__rowNum__;
      row.IMPORT_FORM ? (rowTxt = row.IMPORT_FORM.trim()) : (rowTxt = "");

      // Table 3
      if (rowId >= 7 && rowId <= 33) {
        if (rowId == 7) {
          // Verify wording type ---------------------------------------------------------------------------
          if (rowTxt !== "3. เบี้ยประกันภัยจ่ายครั้งเดียว  Single Premium") {
            showAlertWrongWord(
              "A8",
              "3. เบี้ยประกันภัยจ่ายครั้งเดียว  Single Premium"
            );
            break;
          }
          //-------------------------------------------------------------------------------------------
          type = rowTxt;
        } else if (
          rowId == 8 ||
          rowId == 13 ||
          rowId == 16 ||
          rowId == 25 ||
          rowId == 30
        ) {
          // Verify wording sub_type ---------------------------------------------------------------------------
          if (rowId == 8 && rowTxt !== "3.1 สามัญ Ordinary") {
            showAlertWrongWord("A9", "3.1 สามัญ Ordinary");
            break;
          } else if (rowId == 13 && rowTxt !== "3.2 อุตสาหกรรม Industrial") {
            showAlertWrongWord("A14", "3.2 อุตสาหกรรม Industrial");
            break;
          } else if (rowId == 16 && rowTxt !== "3.3 กลุ่ม Group") {
            showAlertWrongWord("A17", "3.3 กลุ่ม Group");
            break;
          } else if (rowId == 25 && rowTxt !== "3.8 สัญญาเพิ่มเติมรายบุคคล") {
            showAlertWrongWord("A26", "3.8 สัญญาเพิ่มเติมรายบุคคล");
            break;
          } else if (
            rowId == 30 &&
            rowTxt !== "3.9 สัญญาเพิ่มเติมประกันกลุ่ม"
          ) {
            showAlertWrongWord("A31", "3.9 สัญญาเพิ่มเติมประกันกลุ่ม");
            break;
          }
          //-------------------------------------------------------------------------------------------
          sub_type = rowTxt;
        } else {
          if (rowId >= 21 && rowId <= 24) {
            // Verify wording sub_type ---------------------------------------------------------------------------
            if (rowId == 21 && rowTxt !== "3.4 บำนาญ Pension") {
              showAlertWrongWord("A22", "3.4 บำนาญ Pension");
              break;
            } else if (rowId == 22 && rowTxt !== "3.5 Universal Life") {
              showAlertWrongWord("A23", "3.5 Universal Life");
              break;
            } else if (rowId == 23 && rowTxt !== "3.6 Unit-Link") {
              showAlertWrongWord("A24", "3.6 Unit-Link");
              break;
            } else if (rowId == 24 && rowTxt !== "3.7 ตากะฟุล") {
              showAlertWrongWord("A25", "3.7 ตากะฟุล");
              break;
            }
            //-------------------------------------------------------------------------------------------
            sub_type = rowTxt;
          }
          // Verify wording List ---------------------------------------------------------------------------
          if (rowId == 9 && rowTxt !== "- ตลอดชีพ") {
            showAlertWrongWord("A10", "- ตลอดชีพ");
            break;
          } else if (rowId == 10 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A11", "- สะสมทรัพย์");
            break;
          } else if (rowId == 11 && rowTxt !== "- เฉพาะกาล") {
            showAlertWrongWord("A12", "- เฉพาะกาล");
            break;
          } else if (rowId == 12 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A13", "- อื่นๆ");
            break;
          } else if (rowId == 14 && rowTxt !== "- ทั่วไป") {
            showAlertWrongWord("A15", "- ทั่วไป");
            break;
          } else if (rowId == 15 && rowTxt !== "- ไมโครอินชัวรันส์") {
            showAlertWrongWord("A16", "- ไมโครอินชัวรันส์");
            break;
          } else if (rowId == 17 && rowTxt !== "- ชั่วระยะเวลา") {
            showAlertWrongWord("A18", "- ชั่วระยะเวลา");
            break;
          } else if (rowId == 18 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A19", "- สะสมทรัพย์");
            break;
          } else if (rowId == 19 && rowTxt !== "- คุ้มครองสินเชื่อ") {
            showAlertWrongWord("A20", "- คุ้มครองสินเชื่อ");
            break;
          } else if (
            rowId == 20 &&
            rowTxt !== "- อุบัติเหตุ,นักเรียน และอื่นๆ"
          ) {
            showAlertWrongWord("A21", "- อุบัติเหตุ,นักเรียน และอื่นๆ");
            break;
          } else if (rowId == 26 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A27", "- อุบัติเหตุ");
            break;
          } else if (rowId == 27 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A28", "- สุขภาพ");
            break;
          } else if (rowId == 28 && rowTxt !== "- โรคร้ายแรง") {
            showAlertWrongWord("A29", "- โรคร้ายแรง");
            break;
          } else if (rowId == 29 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A30", "- อื่นๆ");
            break;
          } else if (rowId == 31 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A32", "- อุบัติเหตุ");
            break;
          } else if (rowId == 32 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A33", "- สุขภาพ");
            break;
          } else if (rowId == 33 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A34", "- อื่นๆ");
            break;
          }
          //-------------------------------------------------------------------------------------------

          addRow = 1;
          List = rowTxt;
          row.__EMPTY ? (Agent_Policies = row.__EMPTY) : (Agent_Policies = 0);
          row.__EMPTY_1
            ? (Agent_Sum_Insured = row.__EMPTY_1)
            : (Agent_Sum_Insured = 0);
          row.__EMPTY_2
            ? (Agent_Direct_Premiums = row.__EMPTY_2)
            : (Agent_Direct_Premiums = 0);
          row.__EMPTY_3
            ? (Broker_Policies = row.__EMPTY_3)
            : (Broker_Policies = 0);
          row.__EMPTY_4
            ? (Broker_Sum_Insured = row.__EMPTY_4)
            : (Broker_Sum_Insured = 0);
          row.__EMPTY_5
            ? (Broker_Direct_Premiums = row.__EMPTY_5)
            : (Broker_Direct_Premiums = 0);
          row.__EMPTY_6
            ? (Bancassurance_Policies = row.__EMPTY_6)
            : (Bancassurance_Policies = 0);
          row.__EMPTY_7
            ? (Bancassurance_Sum_Insured = row.__EMPTY_7)
            : (Bancassurance_Sum_Insured = 0);
          row.__EMPTY_8
            ? (Bancassurance_Direct_Premiums = row.__EMPTY_8)
            : (Bancassurance_Direct_Premiums = 0);
          row.__EMPTY_9
            ? (Direct_Mail_Policies = row.__EMPTY_9)
            : (Direct_Mail_Policies = 0);
          row.__EMPTY_10
            ? (Direct_Mail_Sum_Insured = row.__EMPTY_10)
            : (Direct_Mail_Sum_Insured = 0);
          row.__EMPTY_11
            ? (Direct_Mail_Direct_Premiums = row.__EMPTY_11)
            : (Direct_Mail_Direct_Premiums = 0);
          row.__EMPTY_12
            ? (Tele_Marketing_Policies = row.__EMPTY_12)
            : (Tele_Marketing_Policies = 0);
          row.__EMPTY_13
            ? (Tele_Marketing_Sum_Insured = row.__EMPTY_13)
            : (Tele_Marketing_Sum_Insured = 0);
          row.__EMPTY_14
            ? (Tele_Marketing_Direct_Premiums = row.__EMPTY_14)
            : (Tele_Marketing_Direct_Premiums = 0);
          row.__EMPTY_15
            ? (Digital_Policies = row.__EMPTY_15)
            : (Digital_Policies = 0);
          row.__EMPTY_16
            ? (Digital_Sum_Insured = row.__EMPTY_16)
            : (Digital_Sum_Insured = 0);
          row.__EMPTY_17
            ? (Digital_Direct_Premiums = row.__EMPTY_17)
            : (Digital_Direct_Premiums = 0);
          row.__EMPTY_18
            ? (Others_Policies = row.__EMPTY_18)
            : (Others_Policies = 0);
          row.__EMPTY_19
            ? (Others_Sum_Insured = row.__EMPTY_19)
            : (Others_Sum_Insured = 0);
          row.__EMPTY_20
            ? (Others_Direct_Premiums = row.__EMPTY_20)
            : (Others_Direct_Premiums = 0);
          row.__EMPTY_21
            ? (Grand_Total_Policies = row.__EMPTY_21)
            : (Grand_Total_Policies = 0);
          row.__EMPTY_22
            ? (Grand_Total_Sum_Insured = row.__EMPTY_22)
            : (Grand_Total_Sum_Insured = 0);
          row.__EMPTY_23
            ? (Grand_Total_Direct_Premiums = row.__EMPTY_23)
            : (Grand_Total_Direct_Premiums = 0);
        }
      }
      // Table 4
      else if (rowId >= 39 && rowId <= 66) {
        if (rowId == 39) {
          // Verify wording type ---------------------------------------------------------------------------
          if (rowTxt !== "4. เบี้ยประกันภัยรวม Total Premium") {
            showAlertWrongWord("A40", "4. เบี้ยประกันภัยรวม Total Premium");
            break;
          }
          //-------------------------------------------------------------------------------------------
          type = rowTxt;
        } else if (
          rowId == 40 ||
          rowId == 45 ||
          rowId == 48 ||
          rowId == 58 ||
          rowId == 63
        ) {
          // Verify wording sub_type ---------------------------------------------------------------------------
          if (rowId == 40 && rowTxt !== "4.1 สามัญ Ordinary") {
            showAlertWrongWord("A41", "4.1 สามัญ Ordinary");
            break;
          } else if (rowId == 45 && rowTxt !== "4.2 อุตสาหกรรม Industrial") {
            showAlertWrongWord("A46", "4.2 อุตสาหกรรม Industrial");
            break;
          } else if (rowId == 48 && rowTxt !== "4.3 กลุ่ม Group") {
            showAlertWrongWord("A49", "4.3 กลุ่ม Group");
            break;
          } else if (rowId == 58 && rowTxt !== "4.9 สัญญาเพิ่มเติมรายบุคคล") {
            showAlertWrongWord("A59", "4.9 สัญญาเพิ่มเติมรายบุคคล");
            break;
          } else if (
            rowId == 63 &&
            rowTxt !== "4.10 สัญญาเพิ่มเติมประกันกลุ่ม"
          ) {
            showAlertWrongWord("A64", "4.10 สัญญาเพิ่มเติมประกันกลุ่ม");
            break;
          }
          //-------------------------------------------------------------------------------------------
          sub_type = rowTxt;
        } else {
          if (rowId >= 53 && rowId <= 57) {
            // Verify wording sub_type ---------------------------------------------------------------------------
            if (rowId == 53 && rowTxt !== "4.4 บำนาญ Pension") {
              showAlertWrongWord("A54", "4.4 บำนาญ Pension");
              break;
            } else if (rowId == 54 && rowTxt !== "4.5 Universal Life") {
              showAlertWrongWord("A55", "4.5 Universal Life");
              break;
            } else if (rowId == 55 && rowTxt !== "4.6 Unit-Link") {
              showAlertWrongWord("A56", "4.6 Unit-Link");
              break;
            } else if (rowId == 56 && rowTxt !== "4.7 ตากะฟุล") {
              showAlertWrongWord("A57", "4.7 ตากะฟุล");
              break;
            } else if (rowId == 57 && rowTxt !== "4.8 อุบัติเหตุส่วนบุคคล") {
              showAlertWrongWord("A58", "4.8 อุบัติเหตุส่วนบุคคล");
              break;
            }
            //-------------------------------------------------------------------------------------------
            sub_type = rowTxt;
          }
          // Verify wording List ---------------------------------------------------------------------------
          if (rowId == 41 && rowTxt !== "- ตลอดชีพ") {
            showAlertWrongWord("A42", "- ตลอดชีพ");
            break;
          } else if (rowId == 42 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A43", "- สะสมทรัพย์");
            break;
          } else if (rowId == 43 && rowTxt !== "- เฉพาะกาล") {
            showAlertWrongWord("A44", "- เฉพาะกาล");
            break;
          } else if (rowId == 44 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A45", "- อื่นๆ");
            break;
          } else if (rowId == 46 && rowTxt !== "- ทั่วไป") {
            showAlertWrongWord("A47", "- ทั่วไป");
            break;
          } else if (rowId == 47 && rowTxt !== "- ไมโครอินชัวรันส์") {
            showAlertWrongWord("A48", "- ไมโครอินชัวรันส์");
            break;
          } else if (rowId == 49 && rowTxt !== "- ชั่วระยะเวลา") {
            showAlertWrongWord("A50", "- ชั่วระยะเวลา");
            break;
          } else if (rowId == 50 && rowTxt !== "- สะสมทรัพย์") {
            showAlertWrongWord("A51", "- สะสมทรัพย์");
            break;
          } else if (rowId == 51 && rowTxt !== "- คุ้มครองสินเชื่อ") {
            showAlertWrongWord("A52", "- คุ้มครองสินเชื่อ");
            break;
          } else if (
            rowId == 52 &&
            rowTxt !== "- อุบัติเหตุ,นักเรียน และอื่นๆ"
          ) {
            showAlertWrongWord("A53", "- อุบัติเหตุ,นักเรียน และอื่นๆ");
            break;
          } else if (rowId == 59 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A60", "- อุบัติเหตุ");
            break;
          } else if (rowId == 60 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A61", "- สุขภาพ");
            break;
          } else if (rowId == 61 && rowTxt !== "- โรคร้ายแรง") {
            showAlertWrongWord("A62", "- โรคร้ายแรง");
            break;
          } else if (rowId == 62 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A63", "- อื่นๆ");
            break;
          } else if (rowId == 64 && rowTxt !== "- อุบัติเหตุ") {
            showAlertWrongWord("A65", "- อุบัติเหตุ");
            break;
          } else if (rowId == 65 && rowTxt !== "- สุขภาพ") {
            showAlertWrongWord("A66", "- สุขภาพ");
            break;
          } else if (rowId == 66 && rowTxt !== "- อื่นๆ") {
            showAlertWrongWord("A67", "- อื่นๆ");
            break;
          }
          //-------------------------------------------------------------------------------------------

          addRow = 1;
          List = rowTxt;
          row.__EMPTY ? (Agent_Policies = row.__EMPTY) : (Agent_Policies = 0);
          row.__EMPTY_1
            ? (Agent_Sum_Insured = row.__EMPTY_1)
            : (Agent_Sum_Insured = 0);
          row.__EMPTY_2
            ? (Agent_Direct_Premiums = row.__EMPTY_2)
            : (Agent_Direct_Premiums = 0);
          row.__EMPTY_3
            ? (Broker_Policies = row.__EMPTY_3)
            : (Broker_Policies = 0);
          row.__EMPTY_4
            ? (Broker_Sum_Insured = row.__EMPTY_4)
            : (Broker_Sum_Insured = 0);
          row.__EMPTY_5
            ? (Broker_Direct_Premiums = row.__EMPTY_5)
            : (Broker_Direct_Premiums = 0);
          row.__EMPTY_6
            ? (Bancassurance_Policies = row.__EMPTY_6)
            : (Bancassurance_Policies = 0);
          row.__EMPTY_7
            ? (Bancassurance_Sum_Insured = row.__EMPTY_7)
            : (Bancassurance_Sum_Insured = 0);
          row.__EMPTY_8
            ? (Bancassurance_Direct_Premiums = row.__EMPTY_8)
            : (Bancassurance_Direct_Premiums = 0);
          row.__EMPTY_9
            ? (Direct_Mail_Policies = row.__EMPTY_9)
            : (Direct_Mail_Policies = 0);
          row.__EMPTY_10
            ? (Direct_Mail_Sum_Insured = row.__EMPTY_10)
            : (Direct_Mail_Sum_Insured = 0);
          row.__EMPTY_11
            ? (Direct_Mail_Direct_Premiums = row.__EMPTY_11)
            : (Direct_Mail_Direct_Premiums = 0);
          row.__EMPTY_12
            ? (Tele_Marketing_Policies = row.__EMPTY_12)
            : (Tele_Marketing_Policies = 0);
          row.__EMPTY_13
            ? (Tele_Marketing_Sum_Insured = row.__EMPTY_13)
            : (Tele_Marketing_Sum_Insured = 0);
          row.__EMPTY_14
            ? (Tele_Marketing_Direct_Premiums = row.__EMPTY_14)
            : (Tele_Marketing_Direct_Premiums = 0);
          row.__EMPTY_15
            ? (Digital_Policies = row.__EMPTY_15)
            : (Digital_Policies = 0);
          row.__EMPTY_16
            ? (Digital_Sum_Insured = row.__EMPTY_16)
            : (Digital_Sum_Insured = 0);
          row.__EMPTY_17
            ? (Digital_Direct_Premiums = row.__EMPTY_17)
            : (Digital_Direct_Premiums = 0);
          row.__EMPTY_18
            ? (Others_Policies = row.__EMPTY_18)
            : (Others_Policies = 0);
          row.__EMPTY_19
            ? (Others_Sum_Insured = row.__EMPTY_19)
            : (Others_Sum_Insured = 0);
          row.__EMPTY_20
            ? (Others_Direct_Premiums = row.__EMPTY_20)
            : (Others_Direct_Premiums = 0);
          row.__EMPTY_21
            ? (Grand_Total_Policies = row.__EMPTY_21)
            : (Grand_Total_Policies = 0);
          row.__EMPTY_22
            ? (Grand_Total_Sum_Insured = row.__EMPTY_22)
            : (Grand_Total_Sum_Insured = 0);
          row.__EMPTY_23
            ? (Grand_Total_Direct_Premiums = row.__EMPTY_23)
            : (Grand_Total_Direct_Premiums = 0);
        }
      }

      if (addRow == 1) {
        // set data row
        dataList.push({
          type: type,
          sub_type: sub_type,
          List: List,
          Agent_Policies: Agent_Policies,
          Agent_Sum_Insured: Agent_Sum_Insured,
          Agent_Direct_Premiums: Agent_Direct_Premiums,
          Broker_Policies: Broker_Policies,
          Broker_Sum_Insured: Broker_Sum_Insured,
          Broker_Direct_Premiums: Broker_Direct_Premiums,
          Bancassurance_Policies: Bancassurance_Policies,
          Bancassurance_Sum_Insured: Bancassurance_Sum_Insured,
          Bancassurance_Direct_Premiums: Bancassurance_Direct_Premiums,
          Direct_Mail_Policies: Direct_Mail_Policies,
          Direct_Mail_Sum_Insured: Direct_Mail_Sum_Insured,
          Direct_Mail_Direct_Premiums: Direct_Mail_Direct_Premiums,
          Tele_Marketing_Policies: Tele_Marketing_Policies,
          Tele_Marketing_Sum_Insured: Tele_Marketing_Sum_Insured,
          Tele_Marketing_Direct_Premiums: Tele_Marketing_Direct_Premiums,
          Digital_Policies: Digital_Policies,
          Digital_Sum_Insured: Digital_Sum_Insured,
          Digital_Direct_Premiums: Digital_Direct_Premiums,
          Others_Policies: Others_Policies,
          Others_Sum_Insured: Others_Sum_Insured,
          Others_Direct_Premiums: Others_Direct_Premiums,
          Grand_Total_Policies: Grand_Total_Policies,
          Grand_Total_Sum_Insured: Grand_Total_Sum_Insured,
          Grand_Total_Direct_Premiums: Grand_Total_Direct_Premiums,
        });
        clearRow();
        addRow = 0;
      }
    }

    // var dataForm = JSON.stringify({
    //   dataList,
    //   month: monthNum,
    //   year: yearEn,
    //   company: companyName,
    //   // company_id: company_id,
    // });
    var dataForm = {
      dataList,
      month: monthNum,
      year: yearEn,
      company: companyName,
    };
    setValuesForm(dataForm);
    // console.log(`dataForm`, dataForm);
  };

  const addDataChannel = () => {
    valuesForm["company_id"] = companyID;
    // var dataImport = JSON.stringify(valuesForm);

    postChannelData(valuesForm).then((response) => {
      if (
        response &&
        (response.status === 200 || response.status === 201) &&
        response.data.success === true
      ) {
        // setOpenModal(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "นำเข้าข้อมูล เรียบร้อย",
          // text: response.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
        // window.location.pathname = `/monthly/editdata/${response.data.tag_money_id}`;
        setOpen(false);
      } else {
        var errMsg = "";
        console.log(
          "API response error1 [" + response.status + "]",
          response.data.message
        );
        // setOpenModal(false);
        setOpen(false);
        if (response.status !== 500) {
          errMsg = response.data.message;
        }
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถนำเข้าข้อมูลได้!",
          text: errMsg,
        });
      }
      handleRemove();
      handleClearData();

      setloadingbutton(false);
    });
  };

  let temptrim = template.substring(8, 16);

  const handleRemove = (jsonData) => {
    setFile(null);
    setFileName(null);
    fileRef.current.value = "";
  };

  const handleClickSearch = () => {
    setLoading(true);
    setLoadingSh(true);
    getData();
  };

  function deleteChannel() {
    let qString = "?";
    if (deleteId.year) qString = qString + "&year=" + deleteId.year;
    if (deleteId.month) qString = qString + "&month=" + deleteId.month;
    if (deleteId.company_id)
      qString = qString + "&company_id=" + deleteId.company_id;

    deleteChannelData(qString).then((res) => {
      if (res && res.status === 200 && res.data.success === true) {
        handleCloseModaldelete();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบข้อมูล เรียบร้อย",
          // title: res.data.message,
          showConfirmButton: false,
          timer: 5000,
          // text: `ข้อมูลจำนวน ${res.data.delete_rows} rows ถูกลบเรียบร้อยแล้ว`,
        });
        getData();
      } else {
        handleCloseModaldelete();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
        });
        console.log(`error deleteChannelData:`, res.data.errors);
      }
      setloadingbuttondelete(false);
    });
  }

  return (
    <Box
      style={{
        margin: "65px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="warning"
          sx={{ width: "100%", fontSize: "16px", padding: 2 }}
        >
          {sumErrorMsg}
        </Alert>
      </Snackbar>

      {/*  ////////////////////////// Main Topic Pages ////////////////////////// */}

      <Box>
        <Typography
          style={{
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "1.9rem",
          }}
        >
          ข้อมูลสถิติแยกตามช่องทางรายเดือน
        </Typography>
      </Box>

      {/*  ////////////////////////// Import Button ////////////////////////// */}
      <Box
        style={{
          display: "flex",
          margin: "20px 0px 20px 0px",
        }}
      >
        <FileDownloadOutlinedIcon style={{ fontSize: "2.2rem" }} /> &nbsp;&nbsp;
        <Typography
          style={{
            color: "#1565c0",
            fontWeight: "400",
            fontSize: "1.4rem",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          Import
        </Typography>
      </Box>

      {/*  ////////////////////////// Search Box ////////////////////////// */}

      <Box sx={{ display: "flex", paddingBottom: "20px" }}>
        <Box sx={{ width: 400, display: "flex", mr: 2 }}>
          <TextField
            placeholder="Search Insurance Company"
            size="middle"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            value={companyname}
            onChange={(e) => {
              setCompanyname(e.target.value);
              console.log(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              views={["year", "month"]}
              label="Year and Month"
              value={value}
              onChange={(newValue) => {
                setValue(newValue.format("YYYY-MM"));
                setValueYear(newValue.format("YYYY"));
                setValueMonth(newValue.format("MM"));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setValue(null);
                      setValueYear(null);
                      setValueMonth(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </LocalizationProvider>
        </Box>

        <LoadingButton
          onClick={handleClickSearch}
          loading={loadingSh}
          loadingIndicator="Loading…"
          variant="contained"
          style={{ width: "80px" }}
        >
          <span style={{ fontSize: "14px" }}>ค้นหา</span>
        </LoadingButton>
      </Box>

      {/*  ////////////////////////// Data Table ////////////////////////// */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    backgroundColor: "#e3f2fd",
                    padding: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      color: "#1565c0",
                      fontSize: "1.25rem",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : dataChannel.length > 0 ? (
              (rowsPerPage > 0
                ? dataChannel.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : dataChannel
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 500 }} align="left">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.company}
                    </Typography>
                    <Typography style={{ fontSize: "1rem" }}>
                      Import Time:{" "}
                      {row.imported_at
                        ? moment(row.imported_at).format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                      {/* {moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")} */}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.month
                        ? moment(row.month.toString()).format("MMM")
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.year}
                    </Typography>
                  </TableCell>

                  <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{
                        fontSize: "1.75rem",
                        fontWeight: "450",
                      }}
                    >
                      <BsFillXCircleFill
                        style={{ color: "#f44336", cursor: "pointer" }}
                        onClick={() => {
                          // setId(row.tag_money_id);
                          getDeleteRow(row.company_id, row.year, row.month);
                          handleOpenModaldelete();
                        }}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={6}
                count={dataChannel.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/*//////////////// Modal popup for Import form "Channel" ////////////////*/}
      <Modal open={open}>
        <Box sx={style}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" style={{ color: "#1565c0" }}>
              Import Report
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                {fileName && (
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#357a38",
                      fontWeight: 400,
                    }}
                  >
                    upload a file already!!
                  </Typography>
                )}
                {!fileName && (
                  <Typography style={{ fontSize: "14px", color: "#9E9E9E" }}>
                    Please upload a file
                  </Typography>
                )}
              </Box>
              <Box>
                <input
                  type="file"
                  accept="xlsx,xls"
                  multiple={false}
                  onChange={(e) => handleFile(e)}
                  ref={fileRef}
                />
                {fileName && (
                  <ClearIcon
                    onClick={() => {
                      handleRemove();
                      handleClearData();
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {fileName && (
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ชื่อบริษัท :{" "}
                </span>
                {company ? (
                  company
                ) : (
                  <span style={{ color: "#c62828" }}>
                    "กรุณาระบุ ชื่อบริษัท"
                  </span>
                )}
              </Typography>

              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ประจำเดือน :{" "}
                </span>
                {reportMonth ? (
                  reportMonth
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ เดือน"</span>
                )}
              </Typography>
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ประจำปี :{" "}
                </span>
                {reportYear ? (
                  reportYear
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ ปี"</span>
                )}
              </Typography>
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ชื่อไฟล์ :{" "}
                </span>
                {fileName ? (
                  fileName
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ ชื่อไฟล์"</span>
                )}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              mt: 5,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography color="error" variant="h6" fontWeight={400}>
                {errorMsg}
              </Typography>
            </Box>
            <Box>
              {/* <Button
                variant="contained"
                size="middle"
                disabled={company && reportMonth && reportYear ? false : true}
                style={{ backgroundColor: "#32B917", marginRight: "15px" }}
                onClick={() => {
                  if (fileName) {
                    handleOpenModal();
                    handleClose();
                    temptrim === "A"
                      ? CheckSummaryFormA()
                      : CheckSummaryFormB();
                  } else {
                    setErrorMsg("** Please choose file excel");
                  }
                }}
              >
                <Typography fontSize={14}>Import</Typography>
              </Button> */}
              <LoadingButton
                color="primary"
                onClick={handleClickModal}
                loading={loadingbutton}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                size="large"
                disabled={company && reportMonth && reportYear ? false : true}
              >
                <span>Save</span>
              </LoadingButton>

              {/* <Button
                variant="contained"
                size="middle"
                color="inherit"
                onClick={() => {
                  handleClose();
                  handleRemove();
                  handleClearData();
                }}
              >
                <Typography fontSize={14}>Back</Typography>
              </Button> */}
              <Button
                style={{ marginLeft: 12 }}
                variant="outlined"
                size="middle"
                color="inherit"
                onClick={() => {
                  handleClose();
                  handleRemove();
                  handleClearData();
                }}
              >
                <Typography fontSize={14}>cancel</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* <Modal open={openModal}>
        <Box sx={styleModal}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography marginTop={1} variant="h5" component="h2">
              Import data form
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleCloseModal} />
            </IconButton>
          </Box>
          <Typography color="error" sx={{ mt: 2 }}>
            {sumErrorMsg}
          </Typography>
          <Typography
            sx={{ mt: 1, color: "#616161" }}
            fontSize={14}
            fontWeight={300}
          >
            Do you want to confirm import data
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5rem",
            }}
          >
            <LoadingButton
              color="primary"
              onClick={handleClickModal}
              loading={loadingbutton}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              size="large"
            >
              <span>Save</span>
            </LoadingButton>
            <Button
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={handleCloseModal}
            >
              <Typography fontSize={14}>cancel</Typography>
            </Button>
          </Box>
        </Box>
      </Modal> */}

      {/* /////////// Modal confirm Delete form /////////// */}

      <Modal open={openModaldelete}>
        <Box sx={styleModaldelete}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography marginTop={1} variant="h5" component="h2">
              Delete data
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleCloseModaldelete} />
            </IconButton>
          </Box>
          <Typography
            sx={{ mt: 3, color: "#616161" }}
            fontSize={14}
            fontWeight={300}
          >
            Do you want to confirm Delete data ?
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5rem",
            }}
          >
            <LoadingButton
              color="error"
              onClick={handleClickModaldelete}
              loading={loadingbuttondelete}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              variant="contained"
              size="large"
            >
              <span>Delete</span>
            </LoadingButton>
            <Button
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={handleCloseModaldelete}
            >
              <Typography fontSize={14}>cancel</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
