import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import {
  getEditform,
  postEditformA,
  postEditformB,
  postConfirm,
} from "../service";
import Swal from "sweetalert2";
import { InputNumber, Select } from "antd";
import ButtonMui from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

import "./Editmonthly.css";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

const styleModalconfirm = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

export default function MonthlyEdit() {
  const [loadingdata, setLoadingdata] = useState(true);
  const [loadingbutton, setloadingbutton] = useState(false);
  const [loadingbuttonconfirm, setloadingbuttonconfirm] = useState(false);

  const [isactive, setIsactive] = useState(true);
  const [dataform, setDataform] = useState([{}]);
  const [values, setValues] = useState({});

  ////////// Modal //////////
  const [open, setOpen] = useState(false);
  const [openconfirm, setOpenconfirm] = useState(false);

  const [company, setCompany] = useState("0");
  const [template, setTemplate] = useState("0");
  const [memberNo, setMemberNo] = useState("0");
  const [yearly, setYearly] = useState("0");
  const [monthy, setMonthy] = useState("0");
  const [dataType, setDataType] = useState("0");
  const [order, setOrder] = useState("0");
  const [isConfirm, setIsConfirm] = useState(null);

  //////////////// Variable of Form A,B ////////////////
  const [ord1, setOrd1] = useState(0);
  const [ord2, setOrd2] = useState(0);
  const [ord3, setOrd3] = useState(0);
  const [getord1, setGetOrd1] = useState(0);
  const [getord2, setGetOrd2] = useState(0);
  const [getord3, setGetOrd3] = useState(0);
  const [statusord1, setStatusOrd1] = useState("input");
  const [statusord2, setStatusOrd2] = useState("input");
  const [statusord3, setStatusOrd3] = useState("input");
  const sumOrd = ord1 + ord2 + ord3;

  const [ind1, setInd1] = useState(0);
  const [ind2, setInd2] = useState(0);
  const [ind3, setInd3] = useState(0);
  const [getind1, setGetInd1] = useState(0);
  const [getind2, setGetInd2] = useState(0);
  const [getind3, setGetInd3] = useState(0);
  const [statusind1, setStatusInd1] = useState("input");
  const [statusind2, setStatusInd2] = useState("input");
  const [statusind3, setStatusInd3] = useState("input");
  const sumInd = ind1 + ind2 + ind3;

  const [term1, setTerm1] = useState(0);
  const [term2, setTerm2] = useState(0);
  const [term3, setTerm3] = useState(0);
  const [getterm1, setGetTerm1] = useState(0);
  const [getterm2, setGetTerm2] = useState(0);
  const [getterm3, setGetTerm3] = useState(0);
  const [statusterm1, setStatusTerm1] = useState("input");
  const [statusterm2, setStatusTerm2] = useState("input");
  const [statusterm3, setStatusTerm3] = useState("input");
  const sumTerm = term1 + term2 + term3;

  const [endo1, setEndo1] = useState(0);
  const [endo2, setEndo2] = useState(0);
  const [endo3, setEndo3] = useState(0);
  const [getendo1, setGetEndo1] = useState(0);
  const [getendo2, setGetEndo2] = useState(0);
  const [getendo3, setGetEndo3] = useState(0);
  const [statusendo1, setStatusEndo1] = useState("input");
  const [statusendo2, setStatusEndo2] = useState("input");
  const [statusendo3, setStatusEndo3] = useState("input");
  const sumEndo = endo1 + endo2 + endo3;

  const [mor1, setMor1] = useState(0);
  const [mor2, setMor2] = useState(0);
  const [mor3, setMor3] = useState(0);
  const [getmor1, setGetMor1] = useState(0);
  const [getmor2, setGetMor2] = useState(0);
  const [getmor3, setGetMor3] = useState(0);
  const [statusmor1, setStatusMor1] = useState("input");
  const [statusmor2, setStatusMor2] = useState("input");
  const [statusmor3, setStatusMor3] = useState("input");
  const sumMor = mor1 + mor2 + mor3;

  const [oth1, setOth1] = useState(0);
  const [oth2, setOth2] = useState(0);
  const [oth3, setOth3] = useState(0);
  const [getoth1, setGetOth1] = useState(0);
  const [getoth2, setGetOth2] = useState(0);
  const [getoth3, setGetOth3] = useState(0);
  const [statusoth1, setStatusOth1] = useState("input");
  const [statusoth2, setStatusOth2] = useState("input");
  const [statusoth3, setStatusOth3] = useState("input");
  const sumOth = oth1 + oth2 + oth3;

  const [PAind1, setPAind1] = useState(0);
  const [PAind2, setPAind2] = useState(0);
  const [PAind3, setPAind3] = useState(0);
  const [getPAind1, setGetPAind1] = useState(0);
  const [getPAind2, setGetPAind2] = useState(0);
  const [getPAind3, setGetPAind3] = useState(0);
  const [statusPAind1, setStatusPAind1] = useState("input");
  const [statusPAind2, setStatusPAind2] = useState("input");
  const [statusPAind3, setStatusPAind3] = useState("input");
  const sumPAind = PAind1 + PAind2 + PAind3;

  const [PAgro1, setPAgro1] = useState(0);
  const [PAgro2, setPAgro2] = useState(0);
  const [PAgro3, setPAgro3] = useState(0);
  const [getPAgro1, setGetPAgro1] = useState(0);
  const [getPAgro2, setGetPAgro2] = useState(0);
  const [getPAgro3, setGetPAgro3] = useState(0);
  const [statusPAgro1, setStatusPAgro1] = useState("input");
  const [statusPAgro2, setStatusPAgro2] = useState("input");
  const [statusPAgro3, setStatusPAgro3] = useState("input");
  const sumPAgro = PAgro1 + PAgro2 + PAgro3;

  const [PAstu1, setPAstu1] = useState(0);
  const [PAstu2, setPAstu2] = useState(0);
  const [PAstu3, setPAstu3] = useState(0);
  const [getPAstu1, setGetPAstu1] = useState(0);
  const [getPAstu2, setGetPAstu2] = useState(0);
  const [getPAstu3, setGetPAstu3] = useState(0);
  const [statusPAstu1, setStatusPAstu1] = useState("input");
  const [statusPAstu2, setStatusPAstu2] = useState("input");
  const [statusPAstu3, setStatusPAstu3] = useState("input");
  const sumPAstu = PAstu1 + PAstu2 + PAstu3;

  const summary1 =
    ord1 + ind1 + term1 + endo1 + mor1 + oth1 + PAind1 + PAgro1 + PAstu1;
  const summary2 =
    ord2 + ind2 + term2 + endo2 + mor2 + oth2 + PAind2 + PAgro2 + PAstu2;
  const summary3 =
    ord3 + ind3 + term3 + endo3 + mor3 + oth3 + PAind3 + PAgro3 + PAstu3;
  const summary4 =
    sumOrd +
    sumInd +
    sumTerm +
    sumEndo +
    sumMor +
    sumOth +
    sumPAind +
    sumPAgro +
    sumPAstu;

  const { id } = useParams();

  let temptrim = template.substring(7, 16);
  let temptrim2 = template.substring(15, 16);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setloadingbutton(false);
  };

  function handleClick() {
    setloadingbutton(true);
    Editdataform();
  }

  const handleOpenconfirm = () => setOpenconfirm(true);
  const handleCloseconfirm = () => {
    setOpenconfirm(false);
  };

  function handleClickConfirm() {
    setloadingbuttonconfirm(true);
    confirmReport();
  }

  ////////// for get DataUser //////////
  React.useEffect(() => {
    getdataform();
  }, []);

  //////////////// confirm Monthly report by id ////////////////

  function confirmReport() {
    let qString = id;

    postConfirm(id, qString).then((response) => {
      console.log("postConfirm: id", id);
      if (response && (response.status === 200 || response.status === 201)) {
        handleCloseconfirm();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Confirm Report เรียบร้อย!",
          showConfirmButton: false,
          timer: 3000,
        });
        window.location.pathname = `/monthly/editdata/${id}`;
      } else {
        console.log(
          "API response error1 [" + response.status + "]",
          response.data.message
        );
        handleCloseconfirm();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `ไม่สามารถ Confirm Report ได้ !! ${response.data.message}`,
        });
      }
      setloadingbuttonconfirm(false);
    });
  }

  ////////////// Get form by id ////////////////

  function getdataform() {
    getEditform(id).then((res) => {
      if (res && res.status === 200) {
        setDataform(res.data[0]);
        setCompany(res.data[0].company_name);
        setTemplate(res.data[0].template);
        setMemberNo(res.data[0].company_code);
        setYearly(res.data[0].tag_money_year);
        setMonthy(res.data[0].tag_money_month);
        setDataType(res.data[0].data_type);
        setOrder(res.data[0].item_qty);
        setIsConfirm(res.data[0].isConfirm);

        res.data[0].value_moneys.map((row) => {
          if (row.ord1 || row.ord1 === 0) {
            setOrd1(row.ord1);
            setGetOrd1(row.ord1);
            row.status === 1
              ? setStatusOrd1("inputupdate")
              : setStatusOrd1("input");
          } else if (row.ord2 || row.ord2 === 0) {
            setOrd2(row.ord2);
            setGetOrd2(row.ord2);
            row.status === 1
              ? setStatusOrd2("inputupdate")
              : setStatusOrd2("input");
          } else if (row.ord3 || row.ord3 === 0) {
            setOrd3(row.ord3);
            setGetOrd3(row.ord3);
            row.status === 1
              ? setStatusOrd3("inputupdate")
              : setStatusOrd3("input");
          } else if (row.ind1 || row.ind1 === 0) {
            setInd1(row.ind1);
            setGetInd1(row.ind1);
            row.status === 1
              ? setStatusInd1("inputupdate")
              : setStatusInd1("input");
          } else if (row.ind2 || row.ind2 === 0) {
            setInd2(row.ind2);
            setGetInd2(row.ind2);
            row.status === 1
              ? setStatusInd2("inputupdate")
              : setStatusInd2("input");
          } else if (row.ind3 || row.ind3 === 0) {
            setInd3(row.ind3);
            setGetInd3(row.ind3);
            row.status === 1
              ? setStatusInd3("inputupdate")
              : setStatusInd3("input");
          } else if (row.term1 || row.term1 === 0) {
            setTerm1(row.term1);
            setGetTerm1(row.term1);
            row.status === 1
              ? setStatusTerm1("inputupdate")
              : setStatusTerm1("input");
          } else if (row.term2 || row.term2 === 0) {
            setTerm2(row.term2);
            setGetTerm2(row.term2);
            row.status === 1
              ? setStatusTerm2("inputupdate")
              : setStatusTerm2("input");
          } else if (row.term3 || row.term3 === 0) {
            setTerm3(row.term3);
            setGetTerm3(row.term3);
            row.status === 1
              ? setStatusTerm3("inputupdate")
              : setStatusTerm3("input");
          } else if (row.endo1 || row.endo1 === 0) {
            setEndo1(row.endo1);
            setGetEndo1(row.endo1);
            row.status === 1
              ? setStatusEndo1("inputupdate")
              : setStatusEndo1("input");
          } else if (row.endo2 || row.endo2 === 0) {
            setEndo2(row.endo2);
            setGetEndo2(row.endo2);
            row.status === 1
              ? setStatusEndo2("inputupdate")
              : setStatusEndo2("input");
          } else if (row.endo3 || row.endo3 === 0) {
            setEndo3(row.endo3);
            setGetEndo3(row.endo3);
            row.status === 1
              ? setStatusEndo3("inputupdate")
              : setStatusEndo3("input");
          } else if (row.mor1 || row.mor1 === 0) {
            setMor1(row.mor1);
            setGetMor1(row.mor1);
            row.status === 1
              ? setStatusMor1("inputupdate")
              : setStatusMor1("input");
          } else if (row.mor2 || row.mor2 === 0) {
            setMor2(row.mor2);
            setGetMor2(row.mor2);
            row.status === 1
              ? setStatusMor2("inputupdate")
              : setStatusMor2("input");
          } else if (row.mor3 || row.mor3 === 0) {
            setMor3(row.mor3);
            setGetMor3(row.mor3);
            row.status === 1
              ? setStatusMor3("inputupdate")
              : setStatusMor3("input");
          } else if (row.oth1 || row.oth1 === 0) {
            setOth1(row.oth1);
            setGetOth1(row.oth1);
            row.status === 1
              ? setStatusOth1("inputupdate")
              : setStatusOth1("input");
          } else if (row.oth2 || row.oth2 === 0) {
            setOth2(row.oth2);
            setGetOth2(row.oth2);
            row.status === 1
              ? setStatusOth2("inputupdate")
              : setStatusOth2("input");
          } else if (row.oth3 || row.oth3 === 0) {
            setOth3(row.oth3);
            setGetOth3(row.oth3);
            row.status === 1
              ? setStatusOth3("inputupdate")
              : setStatusOth3("input");
          } else if (row.PAind1 || row.PAind1 === 0) {
            setPAind1(row.PAind1);
            setGetPAind1(row.PAind1);
            row.status === 1
              ? setStatusPAind1("inputupdate")
              : setStatusPAind1("input");
          } else if (row.PAind2 || row.PAind2 === 0) {
            setPAind2(row.PAind2);
            setGetPAind2(row.PAind2);
            row.status === 1
              ? setStatusPAind2("inputupdate")
              : setStatusPAind2("input");
          } else if (row.PAind3 || row.PAind3 === 0) {
            setPAind3(row.PAind3);
            setGetPAind3(row.PAind3);
            row.status === 1
              ? setStatusPAind3("inputupdate")
              : setStatusPAind3("input");
          } else if (row.PAgro1 || row.PAgro1 === 0) {
            setPAgro1(row.PAgro1);
            setGetPAgro1(row.PAgro1);
            row.status === 1
              ? setStatusPAgro1("inputupdate")
              : setStatusPAgro1("input");
          } else if (row.PAgro2 || row.PAgro2 === 0) {
            setPAgro2(row.PAgro2);
            setGetPAgro2(row.PAgro2);
            row.status === 1
              ? setStatusPAgro2("inputupdate")
              : setStatusPAgro2("input");
          } else if (row.PAgro3 || row.PAgro3 === 0) {
            setPAgro3(row.PAgro3);
            setGetPAgro3(row.PAgro3);
            row.status === 1
              ? setStatusPAgro3("inputupdate")
              : setStatusPAgro3("input");
          } else if (row.PAstu1 || row.PAstu1 === 0) {
            setPAstu1(row.PAstu1);
            setGetPAstu1(row.PAstu1);
            row.status === 1
              ? setStatusPAstu1("inputupdate")
              : setStatusPAstu1("input");
          } else if (row.PAstu2 || row.PAstu2 === 0) {
            setPAstu2(row.PAstu2);
            setGetPAstu2(row.PAstu2);
            row.status === 1
              ? setStatusPAstu2("inputupdate")
              : setStatusPAstu2("input");
          } else if (row.PAstu3 || row.PAstu3 === 0) {
            setPAstu3(row.PAstu3);
            setGetPAstu3(row.PAstu3);
            row.status === 1
              ? setStatusPAstu3("inputupdate")
              : setStatusPAstu3("input");
          }
        });
      }
      setLoadingdata(false);
    });
  }

  //////////////// Edit form ////////////////

  const Editdataform = () => {
    values["company_name"] = company;
    values["template"] = template;
    values["company_code"] = memberNo;
    values["tag_money_year"] = yearly;
    values["tag_money_month"] = monthy;
    values["data_type"] = dataType;
    values["item_qty"] = order;

    if (ord1 !== getord1) values["ord1"] = ord1;
    if (ord2 !== getord2) values["ord2"] = ord2;
    if (ord3 !== getord3) values["ord3"] = ord3;

    if (ind1 !== getind1) values["ind1"] = ind1;
    if (ind2 !== getind2) values["ind2"] = ind2;
    if (ind3 !== getind3) values["ind3"] = ind3;

    if (term1 !== getterm1) values["term1"] = term1;
    if (term2 !== getterm2) values["term2"] = term2;
    if (term3 !== getterm3) values["term3"] = term3;

    if (endo1 !== getendo1) values["endo1"] = endo1;
    if (endo2 !== getendo2) values["endo2"] = endo2;
    if (endo3 !== getendo3) values["endo3"] = endo3;

    if (mor1 !== getmor1) values["mor1"] = mor1;
    if (mor2 !== getmor2) values["mor2"] = mor2;
    if (mor3 !== getmor3) values["mor3"] = mor3;

    if (oth1 !== getoth1) values["oth1"] = oth1;
    if (oth2 !== getoth2) values["oth2"] = oth2;
    if (oth3 !== getoth3) values["oth3"] = oth3;

    if (PAind1 !== getPAind1) values["PAind1"] = PAind1;
    if (PAind2 !== getPAind2) values["PAind2"] = PAind2;
    if (PAind3 !== getPAind3) values["PAind3"] = PAind3;

    if (PAgro1 !== getPAgro1) values["PAgro1"] = PAgro1;
    if (PAgro2 !== getPAgro2) values["PAgro2"] = PAgro2;
    if (PAgro3 !== getPAgro3) values["PAgro3"] = PAgro3;

    if (PAstu1 !== getPAstu1) values["PAstu1"] = PAstu1;
    if (PAstu2 !== getPAstu2) values["PAstu2"] = PAstu2;
    if (PAstu3 !== getPAstu3) values["PAstu3"] = PAstu3;

    if (temptrim == "tplPC1T2A") {
      postEditformA(values, id).then((response) => {
        console.log("postEdit: id", id);
        console.log("postEdit: values", values);

        if (response && (response.status === 200 || response.status === 201)) {
          setOpen(false);

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update data form เรียบร้อย!",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.pathname = `/monthly/editdata/${id}`;
        } else {
          console.log(
            "API response error1 [" + response.status + "]",
            response.data.message
          );
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "ไม่สามารถ Update data form ได้ !!",
          });
        }
        setloadingbutton(false);
      });
    } else {
      postEditformB(values, id).then((response) => {
        console.log("postEdit: id", id);
        console.log("postEdit: values", values);

        if (response && (response.status === 200 || response.status === 201)) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update data form เรียบร้อย!",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.pathname = `/monthly/editdata/${id}`;
        } else {
          console.log(
            "API response error1 [" + response.status + "]",
            response.data.message
          );
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "ไม่สามารถ Update data form ได้ !!",
          });
        }
        setloadingbutton(false);
      });
    }
  };

  return (
    <Box
      style={{
        margin: "65px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box style={{ display: "flex" }}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              to={`/monthly`}
              state={{ dataMonth: monthy, dataYear: yearly }}
              style={{ textDecoration: "none", color: "#9e9e9e" }}
            >
              <Typography
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                }}
              >
                ตรวจสอบข้อมูลรายเดือน
              </Typography>
            </Link>

            <Typography
              style={{
                fontSize: "1.3rem",
                fontWeight: "400",
                color: "#212121",
              }}
            >
              Edit Data
            </Typography>
          </Breadcrumbs>
        </div>
      </Box>

      {/*  ////////////////////////// Main Topic Pages ////////////////////////// */}

      <Box
        style={{
          display: "flex",
          marginTop: "35px",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "2rem",
          }}
        >
          Edit Data
        </Typography>

        <Box>
          {loadingdata ? (
            <Box>
              <Skeleton variant="rounded" height={30} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "8px",
              }}
            >
              <Typography variant="h4">Form {temptrim2}</Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 1 }}>
          {loadingdata ? (
            <Box>
              <Stack spacing={0.3}>
                <Skeleton variant="rounded" height={50} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableHead
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "12px",
                        fontSize: "1.3rem",
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{
                          width: "30rem",
                        }}
                        align="center"
                      >
                        ชื่อบริษัท
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        Template
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        รหัสสมาชิก
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        ค.ศ.
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        ประจำเดือน
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        ประเภทข้อมูล
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        จำนวนรายการ
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "0px",
                        fontSize: "1.1rem",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{ width: "30rem", padding: "10px" }}
                        align="left"
                      >
                        {company}
                      </TableCell>
                      <TableCell
                        style={{ width: "10rem", padding: "10px" }}
                        align="center"
                      >
                        {temptrim}
                      </TableCell>
                      <TableCell
                        style={{ width: "10rem", padding: "10px" }}
                        align="center"
                      >
                        {memberNo}
                      </TableCell>
                      <TableCell
                        style={{ width: "10rem", padding: "10px" }}
                        align="center"
                      >
                        {yearly}
                      </TableCell>
                      <TableCell
                        style={{ width: "10rem", padding: "10px" }}
                        align="center"
                      >
                        {monthy}
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        {!isactive ? (
                          <Select
                            style={{
                              width: "95%",
                              backgroundColor: "pink",
                              borderRadius: "8px",
                            }}
                            defaultValue={dataType}
                            bordered={false}
                            disabled={isactive}
                            options={[
                              { value: "E", label: "E" },
                              { value: "F", label: "F" },
                              { value: "R", label: "R" },
                            ]}
                            onChange={(value) => {
                              setDataType(value);
                              console.log(value);
                            }}
                          />
                        ) : (
                          <Select
                            style={{
                              width: "95%",
                              borderRadius: "8px",
                            }}
                            defaultValue={dataType}
                            bordered={false}
                            disabled={isactive}
                            options={[
                              { value: "E", label: "E" },
                              { value: "F", label: "F" },
                              { value: "R", label: "R" },
                            ]}
                            onChange={(value) => {
                              setDataType(value);
                              console.log(value);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ width: "10rem" }} align="center">
                        <InputNumber
                          id="input"
                          style={{
                            width: "95%",
                          }}
                          min={0}
                          defaultValue={order}
                          value={order}
                          bordered={false}
                          disabled={isactive}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          onChange={(value) => {
                            setOrder(value);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>

        <Box style={{ display: "flex" }}>
          <Box sx={{ mt: 2, width: "100%" }}>
            {loadingdata ? (
              <Box
                style={{
                  width: "100%",
                  marginTop: 9,
                }}
              >
                <Box
                  style={{
                    marginBottom: 9,
                  }}
                >
                  <Skeleton variant="rounded" height={25} />{" "}
                </Box>
                <Stack spacing={0.3}>
                  <Skeleton variant="rounded" height={60} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                  <Skeleton variant="rounded" height={40} />
                </Stack>
              </Box>
            ) : (
              <div>
                {temptrim === "tplPC1T2A" ? (
                  <div>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                      }}
                    >
                      <Typography color="primary" variant="h6">
                        1. การรับประกันภัยรายใหม่
                      </Typography>
                      <Typography color="error" variant="h6">
                        หน่วย : พันบาท
                      </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "12px",
                              fontSize: "1.3rem",
                              backgroundColor: "#e0e0e0",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell align="center" sx={{ width: "50rem" }}>
                              ประเภท
                            </TableCell>
                            <TableCell align="center" sx={{ width: "20rem" }}>
                              จำนวนกรมธรรม์
                            </TableCell>

                            <TableCell align="center" sx={{ width: "20rem" }}>
                              จำนวนคน
                            </TableCell>
                            <TableCell align="center" sx={{ width: "20rem" }}>
                              จำนวนเงินเอาประกันภัย {<br></br>} (ของสัญญาหลัก)
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody
                          sx={{
                            "& .MuiTableCell-root": {
                              //
                              padding: "0px",
                              fontSize: "1.2rem",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สามัญ
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={ord1}
                                value={ord1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ord2}
                                value={ord2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ord3}
                                value={ord3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              อุตสาหกรรม
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={ind1}
                                value={ind1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ind2}
                                value={ind2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ind3}
                                value={ind3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              กลุ่ม
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            ></TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            ></TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบชั่วระยะเวลา (Yearly Renewable
                              Term Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={term1}
                                value={term1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={term2}
                                value={term2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={term3}
                                value={term3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบสะสมทรัพย์ (Endowment
                              Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={endo1}
                                value={endo1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={endo2}
                                value={endo2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={endo3}
                                value={endo3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบคุ้มครองเงินกู้จำนอง (Mortgage
                              Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={mor1}
                                value={mor1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={mor2}
                                value={mor2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={mor3}
                                value={mor3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบอื่น ๆ
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={oth1}
                                value={oth1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={oth2}
                                value={oth2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={oth3}
                                value={oth3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุส่วนบุคคล
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAind1}
                                value={PAind1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAind2}
                                value={PAind2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAind3}
                                value={PAind3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุกลุ่ม
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAgro1}
                                value={PAgro1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAgro2}
                                value={PAgro2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAgro3}
                                value={PAgro3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุสำหรับนักเรียน นิสิต
                              และนักศึกษา
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAstu1}
                                value={PAstu1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAstu2}
                                value={PAstu2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAstu3}
                                value={PAstu3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu3(value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            >
                              รวม
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            >
                              {new Intl.NumberFormat().format(summary1)}
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            >
                              {new Intl.NumberFormat().format(summary2)}
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            >
                              {new Intl.NumberFormat().format(summary3)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                      }}
                    >
                      <Typography color="primary" variant="h6">
                        2. เบี้ยประกันภัย (รวมของสัญญาเพิ่มเติมทุกแบบด้วย)
                      </Typography>
                      <Typography color="error" variant="h6">
                        หน่วย : พันบาท
                      </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "12px",
                              fontSize: "1.3rem",
                              backgroundColor: "#e0e0e0",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell
                              rowSpan={2}
                              align="center"
                              sx={{ width: "50rem" }}
                            >
                              ประเภท
                            </TableCell>
                            <TableCell
                              rowSpan={2}
                              align="center"
                              sx={{ width: "20rem" }}
                            >
                              แบบเบี้ยประกันภัย {<br></br>} จ่ายครั้งเดียว
                            </TableCell>

                            <TableCell
                              colSpan={2}
                              align="center"
                              sx={{ width: "20rem" }}
                            >
                              แบบเบี้ยประกันภัยรายปี
                            </TableCell>
                            <TableCell
                              rowSpan={2}
                              align="center"
                              sx={{ width: "20rem" }}
                            >
                              รวม
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" sx={{ width: "20rem" }}>
                              ปีแรก
                            </TableCell>
                            <TableCell align="center" sx={{ width: "20rem" }}>
                              ปีต่ออายุ
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "0px",
                              fontSize: "1.2rem",
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สามัญ
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={ord1}
                                value={ord1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ord2}
                                value={ord2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusord3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ord3}
                                value={ord3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOrd3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumOrd)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              อุตสาหกรรม
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={ind1}
                                value={ind1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ind2}
                                value={ind2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusind3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={ind3}
                                value={ind3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setInd3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumInd)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              กลุ่ม
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            ></TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            ></TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            ></TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบชั่วระยะเวลา (Yearly Renewable
                              Term Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={term1}
                                value={term1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={term2}
                                value={term2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusterm3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={term3}
                                value={term3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setTerm3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumTerm)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบสะสมทรัพย์ (Endowment
                              Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={endo1}
                                value={endo1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={endo2}
                                value={endo2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusendo3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={endo3}
                                value={endo3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setEndo3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumEndo)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบคุ้มครองเงินกู้จำนอง (Mortgage
                              Insurance)
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={mor1}
                                value={mor1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={mor2}
                                value={mor2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusmor3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={mor3}
                                value={mor3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setMor3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumMor)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              &nbsp; &nbsp; - แบบอื่น ๆ
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={oth1}
                                value={oth1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={oth2}
                                value={oth2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusoth3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={oth3}
                                value={oth3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setOth3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumOth)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุส่วนบุคคล
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAind1}
                                value={PAind1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAind2}
                                value={PAind2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAind3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAind3}
                                value={PAind3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAind3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumPAind)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุกลุ่ม
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAgro1}
                                value={PAgro1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAgro2}
                                value={PAgro2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAgro3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAgro3}
                                value={PAgro3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAgro3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumPAgro)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: "10px" }} align="left">
                              สัญญาหลักประกันภัยอุบัติเหตุสำหรับนักเรียน นิสิต
                              และนักศึกษา
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu1}
                                style={{
                                  width: "95%",
                                }}
                                min={0}
                                defaultValue={PAstu1}
                                value={PAstu1}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu1(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu2}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAstu2}
                                value={PAstu2}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu2(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputNumber
                                id={statusPAstu3}
                                style={{ width: "95%" }}
                                min={0}
                                defaultValue={PAstu3}
                                value={PAstu3}
                                bordered={false}
                                disabled={isactive}
                                formatter={(value) =>
                                  new Intl.NumberFormat().format(value)
                                }
                                onChange={(value) => {
                                  setPAstu3(value);
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(sumPAstu)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="center"
                            >
                              รวม
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(summary1)}
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(summary2)}
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(summary3)}
                            </TableCell>
                            <TableCell
                              style={{ padding: "10px" }}
                              align="right"
                            >
                              {new Intl.NumberFormat().format(summary4)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </div>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          {!isactive && (
            <Box>
              <ButtonMui
                variant="outlined"
                size="middle"
                style={{ marginRight: "15px" }}
                onClick={() => {
                  setIsactive(true);
                  handleOpen();
                }}
              >
                <Typography fontSize={14}>Save Edit Data</Typography>
              </ButtonMui>
              <ButtonMui
                variant="outlined"
                size="middle"
                color="inherit"
                onClick={() => {
                  setIsactive(true);
                }}
              >
                <Typography fontSize={14}>cancel</Typography>
              </ButtonMui>
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
          {isactive && (
            <ButtonMui
              variant="outlined"
              size="middle"
              color="success"
              style={{ marginRight: "15px" }}
              onClick={() => {
                setIsactive(false);
              }}
            >
              <Typography fontSize={14}>Edit Data</Typography>
            </ButtonMui>
          )}
          <ButtonMui
            variant="contained"
            color="success"
            size="middle"
            disabled={isConfirm === 0 ? false : true}
            style={{ marginRight: "15px" }}
            onClick={handleOpenconfirm}
          >
            <Typography fontSize={14}>Confirm</Typography>
          </ButtonMui>
          <Link
            underline="hover"
            to={`/monthly`}
            state={{ dataMonth: monthy, dataYear: yearly }}
            style={{ textDecoration: "none", color: "#212121" }}
          >
            <ButtonMui variant="contained" size="middle" color="inherit">
              <Typography fontSize={14}>Back</Typography>
            </ButtonMui>
          </Link>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleModal}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography marginTop={1} variant="h4" component="h2">
              Save Edit data
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleClose} />
            </IconButton>
          </Box>
          <Typography
            sx={{ mt: 3, color: "#616161" }}
            fontSize={16}
            fontWeight={300}
          >
            Do you want to save edit data form
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5rem",
            }}
          >
            <LoadingButton
              color="primary"
              onClick={handleClick}
              loading={loadingbutton}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              size="large"
            >
              <span>Save</span>
            </LoadingButton>
            <ButtonMui
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={handleClose}
            >
              <Typography fontSize={14}>cancel</Typography>
            </ButtonMui>
          </Box>
        </Box>
      </Modal>

      {/* Confirm Report */}

      <Modal open={openconfirm} onClose={handleCloseconfirm}>
        <Box sx={styleModalconfirm}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography marginTop={1} variant="h4" component="h2">
              Confirm Report
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleCloseconfirm} />
            </IconButton>
          </Box>
          <Typography
            sx={{ mt: 3, color: "#616161" }}
            fontSize={16}
            fontWeight={300}
          >
            Do you want to confirm report
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5rem",
            }}
          >
            <LoadingButton
              color="primary"
              onClick={handleClickConfirm}
              loading={loadingbuttonconfirm}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              size="large"
            >
              <span>confirm</span>
            </LoadingButton>
            <ButtonMui
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={handleCloseconfirm}
            >
              <Typography fontSize={14}>cancel</Typography>
            </ButtonMui>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
