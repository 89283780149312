import "./App.css";
import AppLayout from "./layout/appLayout";
import Login from "./layout/login";
import axios from "axios";
axios.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("tlaa_access_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const login = sessionStorage.getItem("login");
  console.log(`login value`, login);

  return (
    <div className="app">
      <div>{login ? <AppLayout /> : <Login />}</div>
    </div>
  );
}
export default App;
