import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Typography, TextField, Button, MenuItem, Modal } from "@mui/material";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { FiEdit } from "react-icons/fi";
import { BsFillXCircleFill } from "react-icons/bs";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getMonthly,
  deleteMonthly,
  updateImageProfile,
  uploadByChannelReport,
  channelReportList,
  deleteChannelReport,
} from "../service";
import LinearProgress from "@mui/material/LinearProgress";
import { Empty } from "antd";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-month-picker/css/month-picker.css";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

const styleModaldelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  // {
  //   id: "Insurance",
  //   label: "Insurance Company",
  //   align: "left",
  // },
  {
    id: "Report",
    label: "Report",
    align: "left",
  },
  {
    id: "Month",
    label: "Month",
    align: "center",
  },
  {
    id: "Year",
    label: "Year",
    align: "center",
  },
  // {
  //   id: "Form",
  //   label: "Form",
  //   align: "center",
  // },
  {
    id: "ImportTime",
    label: "Import Time",
    align: "center",
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
  },
];

const forms = [
  {
    value: "All",
    label: "-- All Form --",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
];

const statuses = [
  {
    value: "All",
    label: "-- All Status --",
  },
  {
    value: "Confirm",
    label: "Confirm",
  },
  {
    value: "Wait to confirm",
    label: "Wait to confirm",
  },
];

// const monthTH = [
//   "ม.ค.",
//   "ก.พ.",
//   "มี.ค.",
//   "เม.ย.",
//   "พ.ค.",
//   "มิ.ย.",
//   "ก.ค.",
//   "ส.ค.",
//   "ก.ย.",
//   "ต.ค.",
//   "พ.ย.",
//   "ธ.ค.",
// ];

// const monthTH = [
//   { short: "ม.ค.", full: "มกราคม" },
//   { short: "ก.พ.", full: "กุมภาพันธ์" },
//   { short: "มี.ค.", full: "มีนาคม" },
//   { short: "เม.ย.", full: "เมษายน" },
//   { short: "พ.ค.", full: "พฤษภาคม" },
//   { short: "มิ.ย.", full: "มิถุนายน" },
//   { short: "ก.ค.", full: "กรกฎาคม" },
//   { short: "ส.ค.", full: "สิงหาคม" },
//   { short: "ก.ย.", full: "กันยายน" },
//   { short: "ต.ค.", full: "ตุลาคม" },
//   { short: "พ.ย.", full: "พฤศจิกายน" },
//   { short: "ธ.ค.", full: "ธันวาคม" },
// ];

const monthTH = [
  { short: "ม.ค.", full: "มกราคม", num: "01" },
  { short: "ก.พ.", full: "กุมภาพันธ์", num: "02" },
  { short: "มี.ค.", full: "มีนาคม", num: "03" },
  { short: "เม.ย.", full: "เมษายน", num: "04" },
  { short: "พ.ค.", full: "พฤษภาคม", num: "05" },
  { short: "มิ.ย.", full: "มิถุนายน", num: "06" },
  { short: "ก.ค.", full: "กรกฎาคม", num: "07" },
  { short: "ส.ค.", full: "สิงหาคม", num: "08" },
  { short: "ก.ย.", full: "กันยายน", num: "09" },
  { short: "ต.ค.", full: "ตุลาคม", num: "10" },
  { short: "พ.ย.", full: "พฤศจิกายน", num: "11" },
  { short: "ธ.ค.", full: "ธันวาคม", num: "12" },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UploadReport() {
  const fileRef = useRef(null);
  const location = useLocation();
  const [value, setValue] = useState(
    location.state?.dataMonth
      ? `${location.state?.dataYear}-${location.state?.dataMonth}`
      : moment().format("YYYY-MM")
  );
  const [valueYear, setValueYear] = useState(
    location.state?.dataYear
      ? location.state?.dataYear
      : moment().format("YYYY")
  );
  const [valueMonth, setValueMonth] = useState(
    location.state?.dataMonth
      ? location.state?.dataMonth
      : moment().format("MM")
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyname, setCompanyname] = useState("");
  const [form, setForm] = useState("");
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [formCode, setFormCode] = useState("");
  const [reason, setReason] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [sumErrorMsg, setSumErrorMsg] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSh, setLoadingSh] = useState(false);

  const [values, setValues] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingbutton, setloadingbutton] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    handleRemove();
    handleClearData();
    setSumErrorMsg("");
  };

  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [loadingbuttondelete, setloadingbuttondelete] = useState(false);
  const handleOpenModaldelete = () => {
    setOpenModaldelete(true);
  };
  const handleCloseModaldelete = () => setOpenModaldelete(false);

  const handleClickModal = () => {
    setloadingbutton(true);
    uploadZipFile();
  };

  const handleClickModaldelete = () => {
    setloadingbuttondelete(true);
    deleteReport();
  };

  // Snack Bar
  const vertical = "top";
  const horizontal = "right";
  const [openSnack, setOpenSnack] = useState(false);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [dataChannel, setDataChannel] = useState([]);

  const [id, setId] = useState(null);
  const [fileId, setFileId] = useState(null);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getData = () => {
    let qString = "?";
    if (valueYear) qString = qString + "&year=" + valueYear;
    if (valueMonth) qString = qString + "&month=" + valueMonth;

    channelReportList(qString).then((res) => {
      if (res && res.status === 200) {
        setDataChannel(res.data);
      }
      setLoading(false);
      setLoadingSh(false);
    });
  };

  // const [fileType, setFileType] = useState("");
  const [reportMonth, setReportMonth] = useState("");
  const [reportYear, setReportYear] = useState("");
  const [reportMonthNum, setReportMonthNum] = useState("");
  const [reportYearEN, setReportYearEN] = useState("");
  const [upploadReason, setUploadReason] = useState("");
  const [photo, setPhoto] = useState(null);
  const [reportId, setReportId] = useState("");

  // const [company, setCompany] = useState("");
  const [template, setTemplate] = useState("");
  const [memberNo, setMemberNo] = useState("");
  const [yearly, setYearly] = useState("");
  const [monthy, setMonthy] = useState("");
  const [dataType, setDataType] = useState("");
  const [order, setOrder] = useState("");

  const acceptableFileName = ["zip", "7z"];

  const checkFileName = (name) => {
    // return acceptableFileName.includes(name.split(".").pop().toLowerCase());
    return name.includes("zip");
  };

  //////////////// setting RowsPerPage ////////////////
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataChannel.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangestatus = (event) => {
    setStatus(event.target.value);
    if (event.target.value == "Confirm") {
      setStatusCode("1");
    } else if (event.target.value == "Wait to confirm") {
      setStatusCode("0");
    } else if (event.target.value == "All") {
      setStatusCode("");
    }
  };

  const handleClearData = () => {
    // setFileName("");
    setReportMonth("");
    setReportYear("");
    setReportMonthNum("");
    setReportYearEN("");
    setUploadReason("");
    setReportId("");
    setReason("");
  };

  function yearEN(yTH) {
    return yTH - 543;
  }

  //////////////// Import zip files ////////////////

  const handleFile = async (e) => {
    handleClearData();
    const myFile = e.target.files[0];

    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      Swal.fire({
        icon: "warning",
        text: "Invalid File Type!",
      });
      return;
    }

    setFile(myFile);
    setFileName(myFile.name);
    setErrorMsg("");
    setSumErrorMsg("");

    // Verify zip files name
    if (
      // myFile.name.includes("test-upload") ||
      myFile.name.includes("รายงานการรับประกันชีวิตแยกตามช่องทาง")
    ) {
      var filename = myFile.name;
      // Get Year & Month from files name
      for (let i of monthTH) {
        //Find month
        if (filename.includes(i.short)) {
          setReportMonth(i.full);
          setReportMonthNum(i.num);
          //Find year
          let indxMonth = filename.indexOf(i.short);
          let indxYear = filename.indexOf("25", indxMonth);
          let year = filename.substr(indxYear, 4);
          setReportYear(year);
          setReportYearEN(yearEN(year));
          setReportId(`${yearEN(year)}-${i.num}`);
          break;
        }
      }
    } else {
      Swal.fire(
        "Invalid file name?",
        "กรุณาตรวจสอบชื่อไฟล์ ต้องประกอบด้วย 'รายงานการรับประกันชีวิตแยกตามช่องทาง', เดือน, ปี (ไทย)",
        "question"
      );
      handleRemove();
      handleClearData();
    }
  };

  const uploadZipFile = async () => {
    var formData = new FormData();
    // console.log(`test fileRef: `, fileRef);

    if (fileRef) {
      formData.append("myZip", fileRef.current.files[0]);
      formData.append("fname", fileName);
      formData.append("year", reportYearEN);
      formData.append("month", reportMonthNum);
      formData.append("reason", reason);

      // console.log(formData.getAll("myZip"));
      // console.log(formData.getAll("fname"));
      // console.log(formData.getAll("reason"));
    }

    uploadByChannelReport(formData, reportId).then((res) => {
      if (res && res.status === 200 && res.data.success === true) {
        // getData();
        Swal.fire({
          position: "center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
        handleRemove();
        handleClearData();

        handleClose(); // close upload modal
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
        });
        console.log(`error uploadByChannelReport:`, res.data.err);
      }
      setloadingbutton(false);
      setLoading(false);
    });
  };

  let temptrim = template.substring(8, 16);

  const handleRemove = (jsonData) => {
    setFile(null);
    setFileName(null);

    fileRef.current.value = "";
  };

  const handleClickSearch = () => {
    setLoading(true);
    setLoadingSh(true);
    getData();
  };

  function deleteReport() {
    deleteChannelReport(fileId).then((res) => {
      if (res && res.status === 200 && res.data.success === true) {
        handleCloseModaldelete();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Detete Report เรียบร้อย!",
          showConfirmButton: false,
          timer: 5000,
        });
        getData();
      } else {
        handleCloseModaldelete();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
        });
        console.log(`error deleteChannelReport:`, res.data.err);
      }
      setloadingbuttondelete(false);
    });
  }

  return (
    <Box
      style={{
        margin: "65px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="warning"
          sx={{ width: "100%", fontSize: "16px", padding: 2 }}
        >
          {sumErrorMsg}
        </Alert>
      </Snackbar>

      {/*  ////////////////////////// Main Topic Pages ////////////////////////// */}

      <Box>
        <Typography
          style={{
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "1.9rem",
          }}
        >
          Upload File สถิติแยกตามช่องทางรายเดือน
        </Typography>
      </Box>

      {/*  ////////////////////////// Import Button ////////////////////////// */}
      <Box
        style={{
          display: "flex",
          margin: "20px 0px 20px 0px",
        }}
      >
        <DriveFolderUploadOutlinedIcon style={{ fontSize: "2.2rem" }} />{" "}
        &nbsp;&nbsp;
        <Typography
          style={{
            color: "#1565c0",
            fontWeight: "400",
            fontSize: "1.4rem",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          Upload File
        </Typography>
      </Box>

      {/*  ////////////////////////// Search Box ////////////////////////// */}

      <Box sx={{ display: "flex", paddingBottom: "20px" }}>
        <Box sx={{ mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              views={["year", "month"]}
              label="Year and Month"
              value={value}
              onChange={(newValue) => {
                setValue(newValue.format("YYYY-MM"));
                setValueYear(newValue.format("YYYY"));
                setValueMonth(newValue.format("MM"));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setValue(null);
                      setValueYear(null);
                      setValueMonth(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </LocalizationProvider>
        </Box>

        <LoadingButton
          onClick={handleClickSearch}
          loading={loadingSh}
          loadingIndicator="Loading…"
          variant="contained"
          style={{ width: "80px" }}
        >
          <span style={{ fontSize: "14px" }}>ค้นหา</span>
        </LoadingButton>
      </Box>

      {/*  ////////////////////////// Data Table ////////////////////////// */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    backgroundColor: "#e3f2fd",
                    padding: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      color: "#1565c0",
                      fontSize: "1.25rem",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : dataChannel.length > 0 ? (
              (rowsPerPage > 0
                ? dataChannel.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : dataChannel
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 500 }} align="left">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_name}
                    </Typography>
                    <Typography style={{ fontSize: "1rem" }}>
                      {/* Import Time: {row.tag_money_time} */}
                      {/* {row.reason} */}
                      <div
                        className="text-sm text-gray-500 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ __html: row.reason }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_month
                        ? moment(row.report_month.toString()).format("MMM")
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_year}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    <Typography style={{ fontSize: "1rem", fontWeight: "300" }}>
                      {/* {moment(row.updated_at).format("MMMM Do YYYY, h:mm:ss a")} */}
                      {row.last_updated
                        ? moment(row.last_updated).format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{
                        fontSize: "1.75rem",
                        fontWeight: "450",
                      }}
                    >
                      {/* <Link
                        to={`/monthly/editdata/${row.tag_money_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <FiEdit
                          style={{ color: "#4fc3f7", cursor: "pointer" }}
                        />
                      </Link>
                      &nbsp;&nbsp; */}
                      <BsFillXCircleFill
                        style={{ color: "#f44336", cursor: "pointer" }}
                        onClick={() => {
                          setFileId(row.file_id);
                          handleOpenModaldelete();
                        }}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={6}
                count={dataChannel.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/*//////////////// Modal popup for Upload zip files ////////////////*/}
      <Modal open={open}>
        <Box sx={style}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" style={{ color: "#1565c0" }}>
              Upload Zip File
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                {fileName && (
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#357a38",
                      fontWeight: 400,
                    }}
                  >
                    selected zip file already!!
                  </Typography>
                )}
                {!fileName && (
                  <Typography style={{ fontSize: "14px", color: "#9E9E9E" }}>
                    Please select zip file
                  </Typography>
                )}
              </Box>
              <Box>
                <input
                  type="file"
                  // accept="xlsx,xls"
                  // accept="zip"
                  multiple={false}
                  onChange={(e) => handleFile(e)}
                  ref={fileRef}
                  // name="myZip"
                />

                {fileName && (
                  <ClearIcon
                    onClick={() => {
                      handleRemove();
                      handleClearData();
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {fileName && (
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ประจำเดือน :{" "}
                </span>
                {reportMonth ? (
                  reportMonth
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ เดือน"</span>
                )}
              </Typography>
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ประจำปี :{" "}
                </span>
                {reportYear ? (
                  reportYear
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ ปี"</span>
                )}
              </Typography>
              <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ชื่อไฟล์ :{" "}
                </span>
                {fileName ? (
                  fileName
                ) : (
                  <span style={{ color: "#c62828" }}>"กรุณาระบุ ชื่อไฟล์"</span>
                )}
              </Typography>
              {/* <Typography fontSize={14}>
                <span style={{ color: "#1565c0", fontWeight: 500 }}>
                  ประเภทไฟล์ :{" "}
                </span>
                {fileType ? (
                  fileType
                ) : (
                  <span style={{ color: "#c62828" }}>
                    "กรุณาระบุ ประเภทไฟล์"
                  </span>
                )}
              </Typography> */}
            </Box>
          )}

          <br />
          {/* <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          > */}
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              id="reason"
              label="กรุณาใส่เหตุผลการ Upload Zip File ใหม่"
              multiline
              rows={4}
              // defaultValue="Default Value"
              sx={{ m: 1, width: "55ch" }}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </Box>

          <Box
            sx={{
              mt: 5,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography color="error" variant="h6" fontWeight={400}>
                {errorMsg}
              </Typography>
            </Box>
            <Box>
              <LoadingButton
                color="primary"
                onClick={handleClickModal}
                loading={loadingbutton}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                size="large"
                disabled={reportYear && reportMonth ? false : true}
              >
                <span>Upload File</span>
              </LoadingButton>

              <Button
                style={{ marginLeft: 12 }}
                variant="outlined"
                size="middle"
                color="inherit"
                onClick={() => {
                  handleClose();
                  handleRemove();
                  handleClearData();
                }}
              >
                <Typography fontSize={14}>cancel</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* /////////// Modal confirm Delete form /////////// */}

      <Modal open={openModaldelete}>
        <Box sx={styleModaldelete}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography marginTop={1} variant="h5" component="h2">
              Delete report
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleCloseModaldelete} />
            </IconButton>
          </Box>
          <Typography
            sx={{ mt: 3, color: "#616161" }}
            fontSize={14}
            fontWeight={300}
          >
            Do you want to confirm Delete report ?
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "5rem",
            }}
          >
            <LoadingButton
              color="error"
              onClick={handleClickModaldelete}
              loading={loadingbuttondelete}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              variant="contained"
              size="large"
            >
              <span>Delete</span>
            </LoadingButton>
            <Button
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={handleCloseModaldelete}
            >
              <Typography fontSize={14}>cancel</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
