import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Typography, TextField, Button, MenuItem, Modal } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { FiEdit } from "react-icons/fi";
import { BsFillXCircleFill } from "react-icons/bs";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
import { quarterReportList, getQReportLink } from "../service";
import LinearProgress from "@mui/material/LinearProgress";
import { Empty } from "antd";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-month-picker/css/month-picker.css";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import excel from "../../img/excel.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

const styleModaldelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  // {
  //   id: "Insurance",
  //   label: "Insurance Company",
  //   align: "left",
  // },
  {
    id: "Report",
    label: "Report",
    align: "left",
  },
  {
    id: "Quarter",
    label: "Quarter",
    align: "center",
  },
  {
    id: "Year",
    label: "Year",
    align: "center",
  },
  {
    id: "Action",
    label: "Action",
    align: "left",
  },
];

const quarters = [
  {
    value: "All",
    label: "-- All Quarter --",
  },
  {
    value: "Q1",
    label: "Q1",
  },
  {
    value: "Q2",
    label: "Q2",
  },
  {
    value: "Q3",
    label: "Q3",
  },
  {
    value: "Q4",
    label: "Q4",
  },
];

const statuses = [
  {
    value: "All",
    label: "-- All Status --",
  },
  {
    value: "Confirm",
    label: "Confirm",
  },
  {
    value: "Wait to confirm",
    label: "Wait to confirm",
  },
];

// const monthTH = [
//   "ม.ค.",
//   "ก.พ.",
//   "มี.ค.",
//   "เม.ย.",
//   "พ.ค.",
//   "มิ.ย.",
//   "ก.ค.",
//   "ส.ค.",
//   "ก.ย.",
//   "ต.ค.",
//   "พ.ย.",
//   "ธ.ค.",
// ];

// const monthTH = [
//   { short: "ม.ค.", full: "มกราคม" },
//   { short: "ก.พ.", full: "กุมภาพันธ์" },
//   { short: "มี.ค.", full: "มีนาคม" },
//   { short: "เม.ย.", full: "เมษายน" },
//   { short: "พ.ค.", full: "พฤษภาคม" },
//   { short: "มิ.ย.", full: "มิถุนายน" },
//   { short: "ก.ค.", full: "กรกฎาคม" },
//   { short: "ส.ค.", full: "สิงหาคม" },
//   { short: "ก.ย.", full: "กันยายน" },
//   { short: "ต.ค.", full: "ตุลาคม" },
//   { short: "พ.ย.", full: "พฤศจิกายน" },
//   { short: "ธ.ค.", full: "ธันวาคม" },
// ];

const monthTH = [
  { short: "ม.ค.", full: "มกราคม", num: "01" },
  { short: "ก.พ.", full: "กุมภาพันธ์", num: "02" },
  { short: "มี.ค.", full: "มีนาคม", num: "03" },
  { short: "เม.ย.", full: "เมษายน", num: "04" },
  { short: "พ.ค.", full: "พฤษภาคม", num: "05" },
  { short: "มิ.ย.", full: "มิถุนายน", num: "06" },
  { short: "ก.ค.", full: "กรกฎาคม", num: "07" },
  { short: "ส.ค.", full: "สิงหาคม", num: "08" },
  { short: "ก.ย.", full: "กันยายน", num: "09" },
  { short: "ต.ค.", full: "ตุลาคม", num: "10" },
  { short: "พ.ย.", full: "พฤศจิกายน", num: "11" },
  { short: "ธ.ค.", full: "ธันวาคม", num: "12" },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function QuarterReport() {
  // const fileRef = useRef(null);
  const location = useLocation();
  const [value, setValue] = useState(
    location.state?.dataMonth
      ? `${location.state?.dataYear}-${location.state?.dataMonth}`
      : moment().format("YYYY-MM")
  );
  const [valueYear, setValueYear] = useState(
    location.state?.dataYear
      ? location.state?.dataYear
      : moment().format("YYYY")
  );
  const [valueMonth, setValueMonth] = useState(
    location.state?.dataMonth
      ? location.state?.dataMonth
      : moment().format("MM")
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [companyname, setCompanyname] = useState("");
  const [quarter, setQuarter] = useState("");
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [formCode, setFormCode] = useState("");
  const [selectReport, setSelectReport] = React.useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [sumErrorMsg, setSumErrorMsg] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSh, setLoadingSh] = useState(false);

  // const [insurance, setInsurance] = useState([]);
  // const [dataMonthly, setDataMonthly] = useState([]);
  const [values, setValues] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingbutton, setloadingbutton] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    // handleClearData();
    // setSumErrorMsg("");
  };

  const [openModaldelete, setOpenModaldelete] = useState(false);
  const [loadingbuttondelete, setloadingbuttondelete] = useState(false);
  const handleOpenModaldelete = () => {
    setOpenModaldelete(true);
  };
  const handleCloseModaldelete = () => setOpenModaldelete(false);

  const handleClickModal = () => {
    setloadingbutton(true);
    downloadReport();
  };

  // Snack Bar
  const vertical = "top";
  const horizontal = "right";
  const [openSnack, setOpenSnack] = useState(false);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  // const [file, setFile] = useState(null);
  // const [fileName, setFileName] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [dataQuarter, setDataQuarter] = useState([]);

  const [id, setId] = useState(null);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getData = () => {
    let qString = "?";
    if (valueYear) qString = qString + "&year=" + valueYear;
    if (quarter) qString = qString + "&quarter=" + quarter;

    quarterReportList(qString).then((res) => {
      if (res && res.status === 200) {
        setDataQuarter(res.data);
      }
      setLoading(false);
      setLoadingSh(false);
    });
  };

  // const [fileType, setFileType] = useState("");
  // const [reportMonth, setReportMonth] = useState("");
  // const [reportYear, setReportYear] = useState("");
  // const [reportMonthNum, setReportMonthNum] = useState("");
  // const [reportYearEN, setReportYearEN] = useState("");
  // const [upploadReason, setUploadReason] = useState("");
  // const [reportId, setReportId] = useState("");

  // const [company, setCompany] = useState("");
  const [template, setTemplate] = useState("");
  const [memberNo, setMemberNo] = useState("");
  const [yearly, setYearly] = useState("");
  const [monthy, setMonthy] = useState("");
  const [dataType, setDataType] = useState("");
  // const [order, setOrder] = useState("");

  //////////////// Variable of Form A,B ////////////////
  // const [ord1, setOrd1] = useState(0);
  // const [ord2, setOrd2] = useState(0);
  // const [ord3, setOrd3] = useState(0);
  // const sumOrd = ord1 + ord2 + ord3;
  // const [impsumOrd, setImpsumOrd] = useState("");

  // const [ind1, setInd1] = useState(0);
  // const [ind2, setInd2] = useState(0);
  // const [ind3, setInd3] = useState(0);
  // const sumInd = ind1 + ind2 + ind3;
  // const [impsumInd, setImpsumInd] = useState("");

  // const [term1, setTerm1] = useState(0);
  // const [term2, setTerm2] = useState(0);
  // const [term3, setTerm3] = useState(0);
  // const sumTerm = term1 + term2 + term3;
  // const [impsumTerm, setImpsumTerm] = useState("");

  // const [endo1, setEndo1] = useState(0);
  // const [endo2, setEndo2] = useState(0);
  // const [endo3, setEndo3] = useState(0);
  // const sumEndo = endo1 + endo2 + endo3;
  // const [impsumEndo, setImpsumEndo] = useState("");

  // const [mor1, setMor1] = useState(0);
  // const [mor2, setMor2] = useState(0);
  // const [mor3, setMor3] = useState(0);
  // const sumMor = mor1 + mor2 + mor3;
  // const [impsumMor, setImpsumMor] = useState("");

  // const [oth1, setOth1] = useState(0);
  // const [oth2, setOth2] = useState(0);
  // const [oth3, setOth3] = useState(0);
  // const sumOth = oth1 + oth2 + oth3;
  // const [impsumOth, setImpsumOth] = useState("");

  // const [PAind1, setPAind1] = useState(0);
  // const [PAind2, setPAind2] = useState(0);
  // const [PAind3, setPAind3] = useState(0);
  // const sumPAind = PAind1 + PAind2 + PAind3;
  // const [impsumPAind, setImpsumPAind] = useState("");

  // const [PAgro1, setPAgro1] = useState(0);
  // const [PAgro2, setPAgro2] = useState(0);
  // const [PAgro3, setPAgro3] = useState(0);
  // const sumPAgro = PAgro1 + PAgro2 + PAgro3;
  // const [impsumPAgro, setImpsumPAgro] = useState("");

  // const [PAstu1, setPAstu1] = useState(0);
  // const [PAstu2, setPAstu2] = useState(0);
  // const [PAstu3, setPAstu3] = useState(0);
  // const sumPAstu = PAstu1 + PAstu2 + PAstu3;
  // const [impsumPAstu, setImpsumPAstu] = useState("");

  // const [impsummary1, setImpsummary1] = useState("");
  // const [impsummary2, setImpsummary2] = useState("");
  // const [impsummary3, setImpsummary3] = useState("");
  // const [impsummary4, setImpsummary4] = useState("");

  // const summary1 =
  //   ord1 + ind1 + term1 + endo1 + mor1 + oth1 + PAind1 + PAgro1 + PAstu1;
  // const summary2 =
  //   ord2 + ind2 + term2 + endo2 + mor2 + oth2 + PAind2 + PAgro2 + PAstu2;
  // const summary3 =
  //   ord3 + ind3 + term3 + endo3 + mor3 + oth3 + PAind3 + PAgro3 + PAstu3;
  // const summary4 =
  //   sumOrd +
  //   sumInd +
  //   sumTerm +
  //   sumEndo +
  //   sumMor +
  //   sumOth +
  //   sumPAind +
  //   sumPAgro +
  //   sumPAstu;

  // const acceptableFileName = ["zip", "7z"];

  // const checkFileName = (name) => {
  //   // return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  //   return name.includes("zip");
  // };

  //////////////// setting RowsPerPage ////////////////
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataQuarter.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeQuarter = (event) => {
    if (event.target.value == "All") {
      setQuarter("");
    } else {
      setQuarter(event.target.value);
    }
  };

  // const handleChangestatus = (event) => {
  //   setStatus(event.target.value);
  //   if (event.target.value == "Confirm") {
  //     setStatusCode("1");
  //   } else if (event.target.value == "Wait to confirm") {
  //     setStatusCode("0");
  //   } else if (event.target.value == "All") {
  //     setStatusCode("");
  //   }
  // };

  const handleClearData = () => {
    // setReportMonth("");
    // setReportYear("");
    // setReportMonthNum("");
    // setReportYearEN("");
    // setUploadReason("");
    // setReportId("");
  };

  const handleClickSearch = () => {
    setLoading(true);
    setLoadingSh(true);
    getData();
  };

  const downloadReport = () => {
    let qString = "?file_id=" + selectReport.file_id;

    getQReportLink(qString).then((res) => {
      var success = false;
      if (res.headers["content-disposition"]) {
        success = true;
      }
      if (res && res.status === 200 && success !== false) {
        var dwFileName = res.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        var newBlob = new Blob([res.data]);
        // var newBlob = new Blob([res]); //TEST
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            // `Test-Q.xls`
            dwFileName
          );
        }
        // For other browsers:
        // Create link pointing to ObjectURL containing the blob.
        const url = window.URL.createObjectURL(newBlob); //Create blob link to download
        var link = document.createElement("a");
        link.href = url;
        // link.download = `Test-Q.xls`; //fileName
        link.download = dwFileName; //fileName
        document.body.appendChild(link); //Append to html page
        link.click(); //Force download
        document.body.removeChild(link);
        // link.parentNode.removeChild(link); // Clean up and remove the link

        setTimeout(function () {
          // Firefox, necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
        }, 100);

        setloadingbutton(false);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Download Report เรียบร้อย!",
          showConfirmButton: false,
          timer: 1500,
        });
        handleCloseModal();
      } else {
        console.log(`err: The request is taking too long. Please try again`);
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "ไม่สามารถ Download Report ได้ !!",
        });
        setloadingbutton(false);
        handleCloseModal();
      }
    });
  };

  return (
    <Box
      style={{
        margin: "65px 0px 0px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="warning"
          sx={{ width: "100%", fontSize: "16px", padding: 2 }}
        >
          {sumErrorMsg}
        </Alert>
      </Snackbar>

      {/*  ////////////////////////// Main Topic Pages ////////////////////////// */}

      <Box>
        <Typography
          style={{
            fontWeight: "400",
            color: "#1565c0",
            fontSize: "1.9rem",
          }}
        >
          ข้อมูลสถิติไตรมาส
        </Typography>
      </Box>
      <br />

      {/*  ////////////////////////// Import Button ////////////////////////// */}
      {/* <Box
        style={{
          display: "flex",
          margin: "20px 0px 20px 0px",
        }}
      >
        <DriveFolderUploadOutlinedIcon style={{ fontSize: "2.2rem" }} />{" "}
        &nbsp;&nbsp;
        <Typography
          style={{
            color: "#1565c0",
            fontWeight: "400",
            fontSize: "1.4rem",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          Upload File
        </Typography>
      </Box> */}

      {/*  ////////////////////////// Search Box ////////////////////////// */}

      <Box sx={{ display: "flex", paddingBottom: "20px" }}>
        {/* <Box sx={{ width: 400, display: "flex", mr: 2 }}>
          <TextField
            placeholder="Search Insurance Company"
            size="middle"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            value={companyname}
            onChange={(e) => {
              setCompanyname(e.target.value);
              console.log(e.target.value);
            }}
          />
        </Box> */}
        <Box sx={{ mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              //   views={["year", "month"]}
              //   label="Year and Month"
              views={["year"]}
              label="Year"
              value={value}
              onChange={(newValue) => {
                // setValue(newValue.format("YYYY-MM"));
                setValue(newValue.format("YYYY"));
                setValueYear(newValue.format("YYYY"));
                // setValueMonth(newValue.format("MM"));
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setValue(null);
                      setValueYear(null);
                      //   setValueMonth(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ width: 200, mr: 2 }}>
          <TextField
            size="middle"
            variant="outlined"
            fullWidth
            select
            label="Quarter"
            value={quarter}
            onChange={handleChangeQuarter}
          >
            {quarters.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/* <Box sx={{ width: 200, mr: 2 }}>
          <TextField
            size="middle"
            variant="outlined"
            fullWidth
            select
            label="Status"
            value={status}
            onChange={handleChangestatus}
          >
            {statuses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box> */}

        <LoadingButton
          onClick={handleClickSearch}
          loading={loadingSh}
          loadingIndicator="Loading…"
          variant="contained"
          style={{ width: "80px" }}
        >
          <span style={{ fontSize: "14px" }}>ค้นหา</span>
        </LoadingButton>
      </Box>

      {/*  ////////////////////////// Data Table ////////////////////////// */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    backgroundColor: "#e3f2fd",
                    padding: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      color: "#1565c0",
                      fontSize: "1.25rem",
                    }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : dataQuarter.length > 0 ? (
              (rowsPerPage > 0
                ? dataQuarter.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : dataQuarter
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: 500 }} align="left">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_name}
                    </Typography>
                    <Typography style={{ fontSize: "1rem" }}>
                      {/* Import Time: {row.tag_money_time} */}
                      {/* {row.reason} */}
                      <div
                        className="text-sm text-gray-500 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ __html: row.reason }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_quarter}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    <Typography
                      style={{ fontSize: "1.15rem", fontWeight: "450" }}
                    >
                      {row.report_year}
                    </Typography>
                  </TableCell>

                  {/* <TableCell style={{ width: 120 }} align="center">
                    <Typography
                      style={{
                        fontSize: "1.75rem",
                        fontWeight: "450",
                      }}
                    >
                      <Link
                        to={`/monthly/editdata/${row.tag_money_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <FiEdit
                          style={{ color: "#4fc3f7", cursor: "pointer" }}
                        />
                      </Link>
                      &nbsp;&nbsp;
                      <BsFillXCircleFill
                        style={{ color: "#f44336", cursor: "pointer" }}
                        onClick={() => {
                          setId(row.tag_money_id);
                          handleOpenModaldelete();
                        }}
                      />
                    </Typography>
                  </TableCell> */}

                  <TableCell
                    style={{ width: 80, padding: "10px" }}
                    align="left"
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setSelectReport(row);
                        handleOpenModal();
                      }}
                    >
                      <img
                        alt="excelLogo"
                        src={excel}
                        style={{
                          display: "flex",
                          width: "22px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={6}
                count={dataQuarter.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Modal open={openModal}>
        <Box sx={styleModal}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              marginTop={1}
              component="h2"
              fontSize={22}
              fontWeight={700}
            >
              Confirm download report
            </Typography>
            <IconButton size="large">
              <CloseIcon fontSize="inherit" onClick={handleCloseModal} />
            </IconButton>
          </Box>
          <Typography sx={{ mt: 2 }} fontSize={15} fontWeight={500}>
            Do you want to download report ?
            <br />
            Report Name :{" "}
            <Typography display="inline" color="primary" fontSize={15}>
              {selectReport.report_name}
            </Typography>
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "50px",
            }}
          >
            <LoadingButton
              color="primary"
              onClick={handleClickModal}
              loading={loadingbutton}
              loadingPosition="start"
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              size="large"
            >
              <span>Download</span>
            </LoadingButton>
            <Button
              style={{ marginLeft: 12 }}
              variant="outlined"
              size="middle"
              color="inherit"
              onClick={() => {
                setSelectReport({});
                handleCloseModal();
              }}
            >
              <Typography fontSize={14}>cancel</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
