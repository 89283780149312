import { ChartBar as ChartBarIcon } from "../icons/chart-bar";
import SummarizeIcon from "@mui/icons-material/Summarize";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HomeIcon from "@mui/icons-material/Home";
import InputIcon from "@mui/icons-material/Input";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
// import AnalyticsIcon from "@mui/icons-material/Analytics";
// import FolderZipIcon from "@mui/icons-material/FolderZip";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

export const pageListAdmin = [
  {
    title: "Overview",
    pathName: "/dashboard",
    icon: <HomeIcon />,
    manuUser: 1,
  },
  {
    title: "ตรวจสอบข้อมูลรายเดือน",
    pathName: "/monthly",
    icon: <InputIcon />,
    manuUser: 1,
  },
  {
    title: "ออกรายงาน",
    pathName: "/issuereport",
    icon: <DescriptionOutlinedIcon />,
    manuUser: 1,
  },

  {
    title: "สมาชิกในระบบ",
    pathName: "/member",
    icon: <ApartmentIcon />,
    manuUser: 0,
  },
  {
    title: "จัดการผู้ใช้งานระบบ",
    pathName: "/user",
    icon: <PeopleIcon />,
    manuUser: 0,
  },
  {
    title: "Data Visualization",
    pathName: "/visual",
    icon: <AutoGraphOutlinedIcon />,
    manuUser: 1,
    children: [
      {
        title: "ข้อมูลไตรมาส",
        pathName: "/byquarter",
        icon: <DonutSmallOutlinedIcon />,
        manuUser: 1,
      },
      {
        title: "ข้อมูลสถิติแยกตามช่องทาง",
        pathName: "/bychannel",
        icon: <AccountTreeOutlinedIcon />,
        manuUser: 1,
      },
      {
        title: "Upload File สถิติแยกตามช่องทางรายเดือน",
        pathName: "/uploadbychannel",
        icon: <DriveFolderUploadOutlinedIcon />,
        manuUser: 1,
      },
    ],
  },
];

export const pageListUser = [
  {
    title: "Overview",
    pathName: "/dashboard",
    icon: <HomeIcon />,
    manuUser: 1,
  },
  {
    title: "ตรวจสอบข้อมูลรายเดือน",
    pathName: "/monthly",
    icon: <InputIcon />,
    manuUser: 1,
  },
  {
    title: "ออกรายงาน",
    pathName: "/issuereport",
    icon: <DescriptionOutlinedIcon />,
    manuUser: 1,
  },
];
